import { useAuth0 } from '@auth0/auth0-react';
import { Box, Container, Divider, Grid, LinearProgress, Link, Typography, useTheme, Button } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useParams } from 'react-router-dom';
import FullWidthComponent from '../components/FullWidthComponent';
import { PageMeta } from '../components/PageMeta/PageMeta';
import { getAssetUrl } from '../helpers/AssetUrlHelper';
import useAssetInLearningPath from '../hooks/UseAssetInLearningPath';
import UseQuizDetail from '../hooks/UseQuizDetail';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const QuizDetailPage: React.FC = () => {
  let { quizId } = useParams();
  const { data } = UseQuizDetail(quizId);
  const { data: learningPathAssetData } = useAssetInLearningPath(quizId!);
  const { user, isLoading } = useAuth0();
  const theme = useTheme();

  return (
    <>
      {isLoading && <LinearProgress />}
      {!isLoading && data && user && (
        <>
          <PageMeta
            title={`${data?.displayName} on Empowering.Cloud`}
            description={`${data?.displayName} on Empowering.Cloud`}
            openGraphTitle={`${data?.displayName} on Empowering.Cloud`}
            openGraphDescription={`${data?.displayName} on Empowering.Cloud`}
            openGraphImageUrl={data?.thumbnailUrl}
          />
          <Helmet>
            <script async src={`https://s.surveyanyplace.com/js/embed.js`} type="text/javascript" />
          </Helmet>
          <Typography variant="h1" textAlign="center" fontWeight={300} sx={{ pb: 2 }} textTransform="uppercase">
            {data?.displayName}
          </Typography>

          <FullWidthComponent sx={{ paddingY: 4 }}>
            <Container maxWidth="xl">
              <Grid container spacing={2}>
                <Grid item xs={12} lg={8}>
                  <Box
                    className="embed-survey"
                    sx={{ maxHeight: { xs: 500, md: 800 } }}
                    data-url={
                      'https://s.surveyanyplace.com/' +
                      data.embedUrl +
                      '?cf1=' +
                      user['http://empowering.cloud/email'] +
                      '&cf2=' +
                      user['http://empowering.cloud/id']
                    }
                  ></Box>
                </Grid>
                <Grid item xs={12} lg={4}>
                  {user && learningPathAssetData && (
                    <Box sx={{ mb: 2 }}>
                      <Typography variant="h6">Learning Path</Typography>
                      <Divider sx={{ mb: 1 }} />
                      <Box sx={{ pb: 1 }}>
                        <Link component={RouterLink} color={theme.palette.secondary.dark} to={'/learningpaths'} title="Learning Paths">
                          <Typography variant="body1" sx={{ fontWeight: 900 }}>
                            {learningPathAssetData.learningPathName}
                          </Typography>
                        </Link>
                      </Box>
                      {learningPathAssetData.nextAsset && (
                        <>
                          <Typography variant="subtitle1" fontWeight={700}>
                            Next on learning path:
                          </Typography>
                          <Button
                            component={RouterLink}
                            to={getAssetUrl(
                              learningPathAssetData.nextAsset?.id.toString(),
                              learningPathAssetData.nextAsset?.embedUrl,
                              learningPathAssetData.nextAsset?.assetUrl,
                              learningPathAssetData.nextAsset?.assetType?.name,
                            )}
                            variant="outlined"
                            endIcon={<ArrowForwardIcon />}
                            color="secondary"
                            sx={{ width: '100%', textTransform: 'none', display: 'flex' }}
                            data-o-account-activity="VideoPage Next Video Button"
                          >
                            <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center', width: '100%' }}>
                              <Typography>{learningPathAssetData.nextAsset.displayName}</Typography>
                              {learningPathAssetData.nextAsset.lengthMins !== 0 && (
                                <Typography variant="body2">{learningPathAssetData.nextAsset.lengthMins} minutes</Typography>
                              )}
                            </Box>
                          </Button>
                        </>
                      )}
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Container>
          </FullWidthComponent>
        </>
      )}
    </>
  );
};

export default QuizDetailPage;
