import { useEffect, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { PageMeta } from '../components/PageMeta/PageMeta';

declare global {
  interface Window {
    Outseta: any;
  }
}

const AccountManagementPage: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  function getOutseta() {
    if (window.Outseta) {
      return window.Outseta;
    } else {
      throw new Error('Outseta is missing, have you added the script to head?');
    }
  }

  const outsetaRef = useRef(getOutseta());

  useEffect(() => {
    (async () => {
      try {
        outsetaRef.current.profile.open({
          tab: 'plan',
          tabs: 'plan,billing,planChange',
          mode: 'embed',
        });
      } catch (e) {
        console.error(e);
      }
    })();
  }, [getAccessTokenSilently]);

  return (
    <div>
      <PageMeta
        title={`Account Management on Empowering.Cloud`}
        description={`Account Management on Empowering.Cloud`}
        openGraphTitle={`Account Management on Empowering.Cloud`}
        openGraphDescription={`Account Management on Empowering.Cloud`}
      />
      <div id="profile-embed" />
    </div>
  );
};

export default AccountManagementPage;
