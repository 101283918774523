import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, Typography } from '@mui/material';
import { ECCardHorizontal } from '../components/Cards/ECCardHorizontal';
import { ECCardVertical } from '../components/Cards/ECCardVertical';
import { ECCarousel } from '../components/Carousel/ECCarousel';
import useRecentAssets from '../hooks/UseRecentAssets';
import useMonthlyBriefings from '../hooks/UseMonthlyBriefings';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';
import { Asset } from '../hooks/UseAsset';
import { PageMeta } from '../components/PageMeta/PageMeta';
import useLearningPathDetail from '../hooks/UseLearningPathDetail';
import { getAssetUrl } from '../helpers/AssetUrlHelper';
import { useAuth0 } from '@auth0/auth0-react';

const DashboardPage: React.FC = () => {
  const { user } = useAuth0();
  const { status: recentAssetsStatus, data: recentAssets } = useRecentAssets();
  const { status: monthlyBriefingsStatus, data: monthlyBriefings } = useMonthlyBriefings();
  const { data: learningPathData } = useLearningPathDetail();
  const { t } = useTranslation(['dashboard']);

  const mainAsset = recentAssets && recentAssets[0];
  const remainingAssets = recentAssets?.slice(1);

  return (
    <>
      <PageMeta
        title={`Empowering.Cloud - Free on-demand trusted Microsoft Teams advice and training.`}
        description={`Free on-demand trusted Microsoft Teams advice and training.`}
        openGraphTitle={`Empowering.Cloud - Free on-demand trusted Microsoft Teams advice and training.`}
        openGraphDescription={`Free on-demand trusted Microsoft Teams advice and training.`}
      />
      {user && learningPathData && !learningPathData.noLearningPathSet && (
        <Box sx={{ alignItems: 'center', mb: 5 }}>
          <Typography variant="h1" fontWeight={300} sx={{ pb: 2, textAlign: 'center' }} textTransform="uppercase">
            {t('dashboard:nextlearningpath')}
          </Typography>
          <ECCardHorizontal
            title={learningPathData?.asset?.displayName}
            subtitle={learningPathData?.asset?.subtitle}
            imageUrl={learningPathData?.asset?.thumbnailUrl}
            link={getAssetUrl(
              learningPathData?.asset?.id.toString(),
              learningPathData?.asset?.embedUrl,
              learningPathData?.asset?.assetUrl,
              learningPathData?.asset?.assetType?.name,
            )}
          />
        </Box>
      )}
      {recentAssetsStatus === 'success' && (
        <Box sx={{ flexGrow: 1, alignItems: 'center' }}>
          <Grid container>
            <Grid item xs={7} sm={7} md={7}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h1" fontWeight={300} sx={{ pb: 2, marginLeft: 'auto' }} textTransform="uppercase">
                  {t('dashboard:recentupdates')}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={5} sm={5} md={5} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Button component={Link} to={'/briefings'} variant="text" color="inherit">
                  {t('dashboard:showmore')}
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6}>
              {mainAsset && (
                <ECCardVertical
                  title={mainAsset?.displayName}
                  subtitle={mainAsset?.subtitle}
                  imageUrl={mainAsset?.thumbnailUrl}
                  link={`/briefings/${mainAsset?.id}/${mainAsset?.assetUrl}`}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              {remainingAssets &&
                remainingAssets.map((asset: Asset, index: Number) => {
                  return (
                    <ECCardHorizontal
                      title={asset?.displayName}
                      subtitle={asset?.subtitle}
                      imageUrl={asset?.thumbnailUrl}
                      link={`/briefings/${asset?.id}/${asset?.assetUrl}`}
                      key={`recentasset-${index}`}
                    />
                  );
                })}
            </Grid>
          </Grid>
        </Box>
      )}
      {monthlyBriefingsStatus === 'success' && (
        <Box paddingTop={10} sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={7} sm={7} md={7}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography variant="h2" textAlign="center" fontWeight={300} sx={{ pb: 2, marginLeft: 'auto' }} textTransform="uppercase">
                  {t('dashboard:monthlybriefings')}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={5} sm={5} md={5} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Button component={Link} to={'/briefings?q=Monthly+Briefings'} variant="text" color="inherit">
                  {t('dashboard:showmore')}
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              {monthlyBriefings && (
                <ECCarousel>
                  {monthlyBriefings.map((briefing: Asset, index: Number) => (
                    <Box sx={{ marginX: 1, height: '100%' }} key={`monthlybriefing-${index}`}>
                      <ECCardVertical
                        title={briefing?.displayName}
                        subtitle={briefing?.subtitle}
                        imageUrl={briefing?.thumbnailUrl}
                        link={`/briefings/${briefing?.id}/${briefing.assetUrl}`}
                      />
                    </Box>
                  ))}
                </ECCarousel>
              )}
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default DashboardPage;
