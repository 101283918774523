import { useAuth0 } from '@auth0/auth0-react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import ApiProvider from '../providers/ApiProvider';
import { Asset } from './UseAsset';

export default function useQuiz(): UseQueryResult<Asset[], unknown> {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery(['quizzes'], async () => {
    const accessToken = await getAccessTokenSilently();

    const { data } = await ApiProvider.get('/quiz/GetMyQuizzes', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  });
}
