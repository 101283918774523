import { Box, Container, Typography, Link } from '@mui/material';
import { Link as NavLink } from 'react-router-dom';
import EcLogo from '../../styles/eclogo-tagline.png';
import { RouteItems } from '../Navigation/ECNavigationBar';
import { ECFooter, ECFooterBox } from './Footer.styles';

const Footer = () => {
  const footerLinks: RouteItems = [
    { name: 'About', url: 'https://empowering.cloud', isExternal: true },
    { name: 'Privacy Policy', url: 'https://empowering.cloud/privacy-policy', isExternal: true },
    { name: 'Terms of Service', url: 'https://empowering.cloud/terms-of-service', isExternal: true },
    { name: 'Acceptable Use Policy', url: 'https://empowering.cloud/acceptable-use-policy', isExternal: true },
    { name: 'Cookie Policy', url: 'https://empowering.cloud/cookie-policy', isExternal: true },
  ];

  const getYear = () => {
    return new Date().getFullYear();
  };

  return (
    <ECFooter>
      <Container maxWidth="xl">
        <ECFooterBox>
          <NavLink to="/" color="inherit">
            <Box
              component="img"
              sx={{
                height: 70,
                width: 54,
                display: { xs: 'block' },
                mr: 1,
                py: 1,
              }}
              alt="Empowering Cloud Logo"
              src={EcLogo}
            />
          </NavLink>
          <Box component="nav">
            {footerLinks.map((item, index) => (
              <Link
                href={item.url}
                target="_blank"
                variant="footerLinks"
                color="textPrimary"
                sx={{ marginLeft: 3, marginRight: 3, textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
                key={`footerlink-${index}`}
              >
                {item.name}
              </Link>
            ))}
          </Box>
          <Box sx={{ marginLeft: 'auto' }}>
            <Typography color="textSecondary" component="p" variant="caption">
              &copy; {getYear()} Empowering.Cloud Ltd. All Rights Reserved.
            </Typography>
          </Box>
        </ECFooterBox>
      </Container>
    </ECFooter>
  );
};

export default Footer;
