import { Helmet } from 'react-helmet-async';

const Head: React.FC = () => {
  return (
    <Helmet htmlAttributes={{ lang: 'en' }}>
      {/* General tags */}
      <meta charSet="utf-8" />
      <title>Empowering.Cloud</title>
      <meta name="description" content="Free on-demand trusted Microsoft Teams advice and training." />

      {/* OpenGraph tags */}
      <meta property="og:title" content="Empowering.Cloud" />
      <meta property="og:description" content="Free on-demand trusted Microsoft Teams advice and training." />
      <meta property="og:type" content="website" />
    </Helmet>
  );
};

export default Head;
