import 'react-multi-carousel/lib/styles.css';
import { ECCarouselWrapper } from './ECCarousel.styles';

export interface ECCarouselProps {}

export const ECCarousel: React.FC<React.PropsWithChildren<ECCarouselProps>> = ({ children }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 800 },
      items: 3,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 800, min: 0 },
      items: 2,
      paritialVisibilityGutter: 40,
    },
  };

  return (
    <ECCarouselWrapper responsive={responsive} showDots={true}>
      {children}
    </ECCarouselWrapper>
  );
};
