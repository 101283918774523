import { useAuth0 } from '@auth0/auth0-react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import ApiProvider from '../providers/ApiProvider';
import { Asset } from './UseAsset';

export interface AssetInLearningPath {
  previousAsset: Asset;
  nextAsset: Asset;
  learningPathName: string;
}

export default function useAssetInLearningPath(assetId: string | undefined): UseQueryResult<AssetInLearningPath, unknown> {
  const { getAccessTokenSilently, user } = useAuth0();

  return useQuery(
    ['assetInLearningPath', assetId],
    async () => {
      if (!user) {
        return null;
      }

      const accessToken = await getAccessTokenSilently();

      const { data, status } = await ApiProvider.get(`/learningpath/GetAssetInLearningPath?assetId=${assetId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (status === 200) {
        return data;
      }

      return null;
    },
    { enabled: !!assetId },
  );
}
