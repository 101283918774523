import { alpha, Chip, Grid, List, ListItem, ListItemAvatar, ListItemText, styled, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link as NavLink } from 'react-router-dom';
import { PageMeta } from '../components/PageMeta/PageMeta';
import useCertificates, { Certificate } from '../hooks/UseCertificates';
import useWatchHistory, { AssetWatchedProgress } from '../hooks/UseWatchHistory';

const MyECPage: React.FC = () => {
  const { t: translate } = useTranslation(['myec']);
  const { data: certificates } = useCertificates();
  const { data: assetWatchedProgress } = useWatchHistory();

  const ListWithStyles = styled(List)(({ theme }) => ({
    '.MuiListItem-root:hover': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.2),
    },
  }));

  const theme = useTheme();
  return (
    <>
      <PageMeta
        title={`MyEc on Empowering.Cloud`}
        description={`MyEc on Empowering.Cloud`}
        openGraphTitle={`MyEc on Empowering.Cloud`}
        openGraphDescription={`MyEc on Empowering.Cloud`}
      />

      <Typography variant="h1" textAlign="center" fontWeight={300} sx={{ pb: 2 }} textTransform="uppercase">
        My Progress
      </Typography>

      <Grid container spacing={2}>
        {assetWatchedProgress && (
          <Grid item xs={12} md={7}>
            <Typography variant="h6" textAlign="left" fontWeight={300} sx={{ pb: 2 }}>
              Recently Watched
            </Typography>
            <ListWithStyles disablePadding sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {assetWatchedProgress.map((assetWatched: AssetWatchedProgress, index: Number) => (
                <ListItem
                  component={NavLink}
                  to={`/briefings/${assetWatched?.assetId}/${assetWatched?.assetUrl}`}
                  key={`assetwatched-${index}`}
                  sx={{ ':visited': { color: theme.palette.text.primary }, color: theme.palette.text.primary }}
                >
                  <ListItemText primary={assetWatched?.displayName} secondary={`Last Watched: ${moment(assetWatched?.lastWatched).utc().fromNow()}`} />
                  <ListItemAvatar>
                    <Chip label={`${assetWatched.percentWatched}%`} color="secondary" />
                  </ListItemAvatar>
                </ListItem>
              ))}
            </ListWithStyles>
          </Grid>
        )}
        <Grid item xs={12} md={5}>
          <Typography variant="h6" textAlign="left" fontWeight={300} sx={{ pb: 2 }}>
            My Certificates
          </Typography>
          {certificates?.length ? (
            <ListWithStyles disablePadding sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {certificates.map((certificate: Certificate, index: Number) => (
                <ListItem button component="a" data-o-account-activity="cert-accessed" href={certificate?.link} target="_blank" sx={{ cursor: 'pointer' }} key={`certificate-${index}`}>
                  <ListItemText primary={certificate?.name} secondary={`Issued: ${moment(certificate?.issued).utc().format('MMMM Do YYYY')}`} />
                </ListItem>
              ))}
            </ListWithStyles>
          ) : (
            <>{translate('myec:nocertificates')}</>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default MyECPage;
