import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PageMeta } from '../components/PageMeta/PageMeta';
import useManagerReport from '../hooks/UseManagerReportToken';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { Box, Alert } from '@mui/material';
import '../styles/pbiembed.css';

const ManagerReportPage: React.FC = () => {
  const { t: translate } = useTranslation(['navigation']);
  const { data } = useManagerReport();

  return (
    <>
      <PageMeta
        title={`${translate('navigation:reports')}`}
        description={`${translate('navigation:reports')}`}
        openGraphTitle={`${translate('navigation:reports')}`}
        openGraphDescription={`${translate('navigation:reports')}`}
      />
      <Typography variant="h1" textAlign="center" fontWeight={300} sx={{ pb: 2 }} textTransform="uppercase">
        Reports
      </Typography>
      {data && (
        <Box pb={0} textAlign="center">
          <PowerBIEmbed
            embedConfig={{
              type: 'report', // Supported types: report, dashboard, tile, visual and qna
              id: 'b21da826-84aa-4007-ae9b-9c579fa025b1',
              embedUrl: undefined,
              accessToken: data.toString(), // Keep as empty string, null or undefined
              tokenType: models.TokenType.Embed,
              settings: {
                panes: {
                  filters: {
                    expanded: false,
                    visible: true,
                  },
                },
                background: models.BackgroundType.Default,
              },
            }}
            cssClassName="pbi-embed"
          />
          {/* <Alert severity="info">This report is coming soon.</Alert> */}
        </Box>
      )}
    </>
  );
};

export default ManagerReportPage;
