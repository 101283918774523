// import BeamerSetup from './External/BeamerSetup';
import OutsetaSetup from './External/OutsetaSetup';
import IntercommSetup from './External/IntercommSetup';

const ExternalProviderSetupComponent = () => {
  return (
    <>
      <OutsetaSetup />
      <IntercommSetup />
      {/* <BeamerSetup /> */}
    </>
  );
};

export default ExternalProviderSetupComponent;
