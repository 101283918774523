import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { Breadcrumbs, Link } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ECBreadcrumbs = () => {
  const { t } = useTranslation(['navigation']);

  const toTitleCase = (phrase: string | undefined) => {
    if (!phrase) {
      return '';
    }

    return phrase
      .toLowerCase()
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const AuthorBreadcrumb = ({ match }: any) => {
    if (match.params.authorSlug) {
      return <span>{toTitleCase(match.params.authorSlug)}</span>;
    }

    return <></>;
  };

  const ReportBreadcrumb = ({ match }: any) => {
    if (match.params.reportSlug) {
      return <span>{toTitleCase(match.params.reportSlug)}</span>;
    }

    return <></>;
  };

  const routes = [
    { path: '/home', breadcrumb: t('navigation:home') },
    { path: '/benefactors', breadcrumb: t('navigation:benefactors') },
    { path: '/briefings', breadcrumb: t('navigation:briefings') },
    { path: '/briefings/:videoId/:videoDescription' },
    { path: '/quizzes', breadcrumb: t('navigation:quizzes') },
    { path: '/learningpaths', breadcrumb: t('navigation:learningpaths') },
    { path: '/learningpaths/:learningPathId/:learningPathDescription' },
    { path: '/account', breadcrumb: t('navigation:account') },
    { path: '/enablement', breadcrumb: t('navigation:enablement') },
    { path: '/research', breadcrumb: t('navigation:research') },
    { path: '/research/:reportSlug', breadcrumb: ReportBreadcrumb },
    { path: '/researchdemo', breadcrumb: t('navigation:research') },
    { path: '/firstrun', breadcrumb: t('navigation:firstrun') },
    { path: '/myec', breadcrumb: t('navigation:myec') },
    { path: '/community', breadcrumb: t('navigation:community') },
    { path: '/teamsusersync/', breadcrumb: 'Sync' },
    { path: '/teamsusersync/:u', breadcrumb: 'Microsoft Teams User Sync' },
    { path: '/author', breadcrumb: t('navigation:author') },
    { path: '/author/:authorSlug', breadcrumb: AuthorBreadcrumb },
  ];

  const excludedPaths = ['/briefings/:videoId', '/quizzes/:quizId', '/community/:category', '/community/:category/:id', '/learningpaths/:learningPathId'];

  const location = useLocation();
  const breadcrumbs = useBreadcrumbs(routes, { excludePaths: excludedPaths });

  return (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" color="primary" />} aria-label="breadcrumb" id="breadcrumb" data-skip-link="Go to Breadcrumbs">
      {breadcrumbs.map(({ match, breadcrumb }) => (
        <span key={match.pathname}>
          <Link
            component={NavLink}
            variant="breadcrumbs"
            underline="hover"
            color="text.secondary"
            to={match.pathname}
            fontWeight={match.pathname === location.pathname ? '700' : '400'}
          >
            {breadcrumb}
          </Link>
        </span>
      ))}
    </Breadcrumbs>
  );
};

export default ECBreadcrumbs;
