import { styled } from '@mui/material/styles';

export const SkipLinksWithStyles = styled('nav')(({ theme }) => ({
  '& .skip-links': {
    position: 'absolute',
  },
  '& .skip-link': {
    clip: 'rect(0 0 0 0)',
    backgroundColor: theme.palette.secondary.main,
    border: '0',
    color: '#fff',
    display: 'inline-block',
    left: '1rem',
    margin: '-1px',
    minWidth: '12vw',
    overflow: 'hidden',
    padding: '1rem',
    position: 'absolute',
    textAlign: 'center',
    zIndex: '2',
  },
  '& .skip-link:focus': {
    clip: 'auto',
    height: 'auto',
    margin: '0',
    overflow: 'visible',
    width: 'auto',
    marginTop: '15px',
  },
}));
