import { useQuery, UseQueryResult } from '@tanstack/react-query';
import ApiProvider from '../providers/ApiProvider';

export type AuthorBasic = {
  name: string;
  pictureUrl: string;
  shortDescription: string;
  urlSlug: string;
};

const getAuthors = async (): Promise<AuthorBasic[]> => {
  const { data } = await ApiProvider.get('/authors');
  return data;
};

export default function useAuthors(): UseQueryResult<AuthorBasic[], unknown> {
  return useQuery(['page-authors'], getAuthors);
}
