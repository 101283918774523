import { useQuery, UseQueryResult } from '@tanstack/react-query';
import ApiProvider from '../providers/ApiProvider';
import { Asset } from './UseAsset';

const getMonthlyBriefings = async (): Promise<Asset[]> => {
  const { data } = await ApiProvider.get('/asset/GetMonthlyBriefings?count=5');
  return data;
};

export default function useMonthlyBriefings(): UseQueryResult<Asset[], unknown> {
  return useQuery(['monthlyBriefings'], getMonthlyBriefings);
}
