import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import common from './en/common.json';
import navigation from './en/navigation.json';
import dashboard from './en/dashboard.json';
import myec from './en/myec.json';

export const defaultNS = 'common';
export const resources = {
  en: {
    common,
    navigation,
    dashboard,
    myec,
  },
} as const;

i18n.use(initReactI18next).init({
  lng: 'en',
  ns: ['common', 'navigation', 'dashboard', 'myec'],
  defaultNS,
  resources,
  debug: process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});
