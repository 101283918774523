import { IconButton, TextField } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

export const SearchInputWithStyles = styled(TextField)(({ theme }) => ({
  width: '40vw',
  '& .MuiInputBase-root-MuiInput-root:after': {
    borderBottom: '2px solid #fff',
  },
  input: {
    color: '#fff',
    fontSize: '4em',
    textAlign: 'center',
    borderBottom: 'none',
    '&::placeholder': {
      textAlign: 'center',
      color: '#fff',
    },
  },
  '& .Mui-focused::after': {
    borderBottom: '2px solid #fff',
  },
  '& .MuiInput-underline:before': {
    borderBottom: '2px solid #797979',
  },
  '& .MuiInput-root:hover:before': {
    borderBottom: '2px solid #797979',
  },
  '& .MuiInput-underline:after': {
    borderBottom: '2px solid #fff',
  },
  [theme.breakpoints.down('sm')]: {
    width: '80vw',
    input: {
      fontSize: '1.5em',
    },
  },
}));

export const CloseButtonWithStyles = styled(IconButton)(({ theme }) => ({
  stroke: alpha('#fff', 0.7),
  strokeWidth: 2,
  '&:hover': {
    stroke: '#fff',
  },
}));
