import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import UserAvatar, { UserAvatarProps } from '../components/Auth/UserAvatar';
import AssetCard from '../components/Cards/AssetCard/AssetCard';
import { PageMeta } from '../components/PageMeta/PageMeta';
import useResearchReports from '../hooks/UseResearchReports';
import { ReportBasic } from '../hooks/UseResearchReports';

const ResearchReportsPage: React.FC = () => {
  const { t: translate } = useTranslation(['navigation']);
  const { data } = useResearchReports();

  return (
    <>
      <PageMeta
        title={`${translate('navigation:research')}`}
        description={`${translate('navigation:research')}`}
        openGraphTitle={`${translate('navigation:research')}`}
        openGraphDescription={`${translate('navigation:research')}`}
      />
      <Typography variant="h1" textAlign="center" fontWeight={300} sx={{ pb: 2 }} textTransform="uppercase">
        Research
      </Typography>
      {/* {data && (
        <Grid container spacing={2}>
          {data.map((report: ReportBasic, index: number) => (
            <Grid item xs={12} md={6} lg={4} key={`author-${index}`}>
              <AssetCard
                ID={index}
                Size="sm"
                DisplayName={report.reportName}
                Subtitle={report.reportShortDescription}
                ThumbnailUrl={report.thumbnailUrl}
                ThumbnailVariant="rounded"
                Link={`/research/${report.urlSlug}`}
                FakeDisabled={!report.canBeShown}
              />
            </Grid>
          ))}
        </Grid>
      )} */}
      <Typography variant="h4" textAlign="center" fontWeight={300} sx={{ pb: 2 }}>
        We have moved all our research reports over to a new platform at <a href="https://members.empowering.cloud">members.empowering.cloud</a> which allows us
        to add more types of content and update it faster. Please sign in over there to view all our research, free to access to community members.
      </Typography>
    </>
  );
};

export default ResearchReportsPage;
