import { LinearProgress } from '@mui/material';
import { Suspense } from 'react';

interface AsyncComponentProps {}

const AsyncComponent: React.FC<React.PropsWithChildren<AsyncComponentProps>> = ({ children }) => {
  return <Suspense fallback={<LinearProgress />}>{children}</Suspense>;
};

export default AsyncComponent;
