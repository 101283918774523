import { Button, Paper, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const NotFoundPage: React.FC = () => {
  return (
    <Paper elevation={0} sx={{ padding: 5, textAlign: 'center' }}>
      <Typography variant="h1" fontWeight={700}>
        Oops - We can't find that page!
      </Typography>
      <Typography variant="body1">The page you're trying to access doesn't exist, we're really sorry about that.</Typography>
      <Button component={RouterLink} to="/" variant="contained" disableElevation sx={{ mt: 3 }}>
        Empowering.Cloud Home
      </Button>
    </Paper>
  );
};

export default NotFoundPage;
