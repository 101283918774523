import { Box, Container, CssBaseline } from '@mui/material';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import NavBar from '../components/NavBar';
import { SkipLinks } from '../components/SkipLinks/SkipLinks';

interface Props {}

const Layout: React.FC<React.PropsWithChildren<Props>> = ({ children }) => {
  return (
    <>
      <CssBaseline />
      <SkipLinks />
      <Box sx={{ display: 'flex', minHeight: '100vh', flexDirection: 'column', justifyContent: 'flex-start' }}>
        <Header />
        <NavBar />
        <Container component="main" maxWidth="xl" sx={{ py: 3 }} data-skip-link="Go to Main Content" id="main-content">
          {children}
        </Container>
        <Footer />
      </Box>
    </>
  );
};

export default Layout;
