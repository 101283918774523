import { SkipLinksWithStyles } from './SkipLinks.styles';
import { Link, useSkipLinks } from './UseSkipLinks';

export const SkipLinks = () => {
  const { skipLinks } = useSkipLinks();

  if (!skipLinks.length) return null;

  return (
    <SkipLinksWithStyles aria-label="skipLinks" className="skip-links">
      {skipLinks.map((link: Link) => (
        <a href={`#${link.id}`} className="skip-link" key={`skiplink-${link.id}`}>
          {link.title}
        </a>
      ))}
    </SkipLinksWithStyles>
  );
};
