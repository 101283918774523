import { useAuth0 } from '@auth0/auth0-react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import ApiProvider from '../providers/ApiProvider';
import { Asset } from './UseAsset';

export type LearningPathDetail = {
  userId: string;
  nextLearningPathAssetName: string;
  nextLearningPathAssetId: string;
  asset_ID: string;
  asset: Asset;
  primaryLearningPathName: string;
  primaryLearningPathCompletionPercentage: number;
  noLearningPathSet: boolean;
};

export default function useLearningPathDetail(): UseQueryResult<LearningPathDetail, unknown> {
  const { user, getAccessTokenSilently } = useAuth0();

  return useQuery(
    ['learningPathDetail'],
    async () => {
      const accessToken = await getAccessTokenSilently();

      const { data } = await ApiProvider.get('/learningpath/GetMyLearningPathState', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return data;
    },
    { enabled: !!user },
  );
}
