import { Paper, SxProps, Theme } from '@mui/material';

interface FullWidthComponentProps {
  sx?: SxProps<Theme> | undefined;
}

const FullWidthComponent: React.FC<React.PropsWithChildren<FullWidthComponentProps>> = ({ children, sx }) => {
  return (
    <Paper sx={{ ...sx, width: 'calc(100vw - 8px)', position: 'relative', left: '50%', right: '50%', marginLeft: '-50vw', marginRight: '-50vw' }} elevation={0}>
      {children}
    </Paper>
  );
};

export default FullWidthComponent;
