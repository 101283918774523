import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, List, ListItem, Paper, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

export const NotLoggedIn = () => {
  const { loginWithRedirect } = useAuth0();
  const location = useLocation();

  return (
    <>
      <Box sx={{ p: 2, alignContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
        <Paper elevation={0} sx={{ p: 2 }}>
          <Typography variant="h4">Join over 3,000 people in the Empowering.Cloud Community Today.</Typography>
          <Typography variant="h6" pt={2}>
            To watch this briefing you need to sign in with email or your LinkedIn account.
          </Typography>
          <Typography variant="body2" pt={1}>
            Empowering.Cloud's mission is to provide IT Professionals with the best and most up to date Microsoft Teams and ecosystem insights, advice and
            training free on-demand.
          </Typography>
          <List disablePadding sx={{ width: '100%', paddingTop: 1 }}>
            <ListItem sx={{ textAlign: 'center', display: 'block', paddingY: 0, paddingBottom: 1 }}>
              Monthly Microsoft Teams News and Updates Briefings
            </ListItem>
            <ListItem sx={{ textAlign: 'center', display: 'block', paddingY: 0, paddingBottom: 1 }}>
              Microsoft Teams and ecosystem advice from subject matter experts
            </ListItem>
            <ListItem sx={{ textAlign: 'center', display: 'block', paddingY: 0, paddingBottom: 1 }}>
              Leaning Paths and training to pass Microsoft Teams exams
            </ListItem>
            <ListItem sx={{ textAlign: 'center', display: 'block', paddingY: 0, paddingBottom: 1 }}>
              Empowering.Cloud Teams app to keep you up to date on Teams in Teams
            </ListItem>
          </List>
          <Typography variant="body2" pt={1}>
            Empowering.Cloud will always provide the best Microsoft Teams insights, advice and training free for IT Professionals, its core to our mission. We
            keep Empowering.Cloud free thanks to our industry Benefactors and Supporters.
          </Typography>
          <Typography variant="body2" pt={1}>
            Premium paid memberships are available for organisations to provide their teams with additional benefits such as custom learning paths and expert
            office hours.
          </Typography>
          <Box pt={2}>
            <Button
              variant="contained"
              size="large"
              disableElevation
              sx={{ marginRight: 2 }}
              onClick={() => {
                loginWithRedirect({ appState: { returnTo: location.pathname } });
              }}
            >
              Sign In
            </Button>
            <Button
              variant="contained"
              size="large"
              disableElevation
              onClick={() =>
                loginWithRedirect({
                  authorizationParams: { screen_hint: 'signup' },
                })
              }
            >
              Sign Up
            </Button>
          </Box>
        </Paper>
      </Box>
    </>
  );
};
