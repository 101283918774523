import { Card, CardContent, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import UserAvatar from '../components/Auth/UserAvatar';
import { PageMeta } from '../components/PageMeta/PageMeta';
import SocialIcon from '../components/SocialIcons/social-icon';
import useAuthor from '../hooks/UseAuthor';
import { Image } from 'mui-image';
import MicrosoftMvpImage from '../components/SocialIcons/Microsoft_MVP_banner.png';
import BlogImage from '../components/SocialIcons/blogicon.png';
import AssetTable from '../components/AssetTable/AssetTable';

const AuthorDetailPage: React.FC = () => {
  let { authorSlug } = useParams();
  const { data } = useAuthor(authorSlug);

  return (
    <>
      <PageMeta
        title={`${data?.name} - Empowering.Cloud Author`}
        description={`${data?.name} - Empowering.Cloud Author`}
        openGraphTitle={`${data?.name} - Empowering.Cloud Author`}
        openGraphDescription={`${data?.name} - Empowering.Cloud Author`}
      />

      <Typography variant="h1" textAlign="center" fontWeight={300} sx={{ pb: 2 }} textTransform="uppercase">
        {data?.name}
      </Typography>

      {data && (
        <Card>
          <CardContent>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              alignItems={{ xs: 'center', md: 'flex-start' }}
              spacing={2}
              mb={2}
            >
              <UserAvatar authorName={data?.name} authorImageSrc={data?.pictureUrl} variant="rounded" size="lg" />
              <Stack direction="column" spacing={0} alignItems={{ xs: 'center', md: 'flex-start' }}>
                <Typography variant="h4" variantMapping={{ h4: 'h2' }}>
                  {data.name}
                </Typography>
                <Typography variant="body2">{data.shortDescription}</Typography>
                <Stack direction="row" my={1} spacing={1}>
                  {data.linkedInLink && <SocialIcon network="linkedin" url={data.linkedInLink} />}
                  {data.twitterLink && <SocialIcon network="twitter" url={data.twitterLink} />}
                  {data.youTubeLink && <SocialIcon network="youtube" url={data.youTubeLink} />}
                  {data.blogLink && (
                    <a href={data.blogLink} target="_blank" rel="noreferrer">
                      <Image src={BlogImage} alt="Blog" fit="contain" height={32} width={32} duration={0} />
                    </a>
                  )}
                </Stack>
                {data.mvpLink && (
                  <a href={data.mvpLink} target="_blank" rel="noreferrer">
                    <Image src={MicrosoftMvpImage} alt="Microsoft MVP" fit="contain" height={40} duration={0} />
                  </a>
                )}
              </Stack>
            </Stack>
            <Typography textAlign={{ xs: 'center', md: 'left' }}>{data.longDescription}</Typography>
          </CardContent>
        </Card>
      )}
      <Typography variant="h1" textAlign="center" fontWeight={300} sx={{ py: 3 }} textTransform="uppercase">
        Recent Briefings
      </Typography>
      {data && data.assets && <AssetTable assets={data.assets} />}
    </>
  );
};

export default AuthorDetailPage;
