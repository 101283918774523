import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

interface Auth0ComponentProps {}

const Auth0Component: React.FC<React.PropsWithChildren<Auth0ComponentProps>> = ({ children }) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState: any) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain="auth.empowering.cloud"
      clientId="OpR0KVFIl7gw7F3RP9qGLe7fpW8lhtYX"
      cacheLocation="localstorage"
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
      onRedirectCallback={onRedirectCallback}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: 'https://empowering.cloud/api',
      }}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0Component;
