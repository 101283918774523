import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { useParams } from 'react-router-dom';
import { PageMeta } from '../components/PageMeta/PageMeta';
import useLearningPathIndividualDetail from '../hooks/UseLearningPathIndividualDetail';
import { useJoinLearningPath, useLeaveLearningPath } from '../hooks/UseLearningPathMutation';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import LearningPathsTable from '../components/LearningPaths/LearningPathTable';
import moment from 'moment';

const LearningPathDetailPage: React.FC = () => {
  let { learningPathId, learningPathDescription } = useParams();
  const { data } = useLearningPathIndividualDetail(Number(learningPathId));
  const { isLoading: isJoinLearningPathLoading, mutate: joinLearningPath } = useJoinLearningPath();
  const { isLoading: isLeaveLearningPathLoading, mutate: leaveLearningPath } = useLeaveLearningPath();
  const confirm = useConfirm();

  const handleLeaveLearningPath = (learningPathId: number, learningPathName: string) => {
    confirm({ description: `Are you sure you want to leave learning path, ${learningPathName}?` })
      .then(() => {
        leaveLearningPath(learningPathId);
      })
      .catch(() => {});
  };

  return (
    <>
      <PageMeta title={`Learning Path ${data?.learningPathName} on Empowering.Cloud`} />

      <Typography variant="h1" textAlign="center" fontWeight={300} textTransform="uppercase">
        {data?.learningPathName}
      </Typography>

      <Typography variant="body1" fontSize="medium" textAlign="center" fontWeight={300}>
        {data?.learningPathDescription}
      </Typography>

      {data?.started && (
        <Typography variant="body1" fontSize="medium" textAlign="center" fontWeight={300}>
          Started: {moment(data?.started).format('MMMM Do YYYY')}
        </Typography>
      )}

      <Box sx={{ pt: 2 }} display="flex" flexDirection="column" alignItems="center">
        {data?.started && (
          <LoadingButton
            onClick={() => handleLeaveLearningPath(Number(learningPathId), data?.learningPathName ?? '')}
            endIcon={<ExitToAppIcon />}
            loading={isLeaveLearningPathLoading}
            loadingPosition="end"
            variant="contained"
          >
            Leave this path
          </LoadingButton>
        )}

        {!data?.started && (
          <LoadingButton
            onClick={() => joinLearningPath(Number(learningPathId))}
            endIcon={<HowToRegIcon />}
            loading={isJoinLearningPathLoading}
            loadingPosition="end"
            variant="contained"
          >
            Join this path
          </LoadingButton>
        )}
      </Box>

      <Box mt={6}>{data && <LearningPathsTable learningPath={data} />}</Box>
    </>
  );
};

export default LearningPathDetailPage;
