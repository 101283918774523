import * as React from 'react';
import { alpha, Backdrop, Box, Container } from '@mui/material';
import { CloseButtonWithStyles, SearchInputWithStyles } from './ECSearch.styles';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';

export interface ECSearchProps {
  isSearchOpen: boolean;
  handleClose: () => void;
}

type FormData = {
  search: string;
};

export const ECSearch: React.FC<ECSearchProps> = props => {
  const { isSearchOpen, handleClose } = props;
  const { control, reset, getValues } = useForm<FormData>();
  const navigate = useNavigate();

  React.useEffect(() => {
    const keyDownHandler = (event: { key: string; preventDefault: () => void }) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        handleClose();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [handleClose]);

  React.useEffect(() => {
    reset({ search: '' });
    const body = document.querySelector('body');
    body!.style.overflow = isSearchOpen ? 'hidden' : 'auto';
  }, [isSearchOpen]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const searchValue = getValues('search');
    e.preventDefault();
    navigate(`/briefings?q=${searchValue}`);
    handleClose();
  };

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1, backgroundColor: alpha('#000', 0.9), flexDirection: 'column' }}
      open={isSearchOpen}
    >
      <Container maxWidth="xl">
        <Box sx={{ marginTop: '-200px', paddingBottom: '100px', display: 'flex', justifyContent: 'end' }}>
          <CloseButtonWithStyles aria-label="open drawer" edge="start" onClick={handleClose}>
            <CloseIcon sx={{ fontSize: { md: 48, sm: 24 } }} />
          </CloseButtonWithStyles>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <form onSubmit={handleSubmit}>
            <Controller
              render={({ field }) => (
                <SearchInputWithStyles inputRef={input => input && input.focus()} id="standard-basic" variant="standard" placeholder="Search..." {...field} />
              )}
              name="search"
              control={control}
              rules={{ required: true }}
            />
          </form>
        </Box>
      </Container>
    </Backdrop>
  );
};
