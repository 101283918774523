import { useAuth0 } from '@auth0/auth0-react';
import ApiProvider from '../providers/ApiProvider';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

export default function useTeamsSync(conversationId: string | undefined): UseQueryResult<boolean, unknown> {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery(
    ['conversation', conversationId],

    async () => {
      const accessToken = await getAccessTokenSilently();
      return ApiProvider.post(
        `/me/SyncTeamsUser?conversationId=${conversationId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )
        .then(() => {
          console.log('Synced Teams User');
          return true;
        })
        .catch(error => {
          console.log('error', error);
          return false;
        });
    },
    { enabled: !!conversationId },
  );
}
