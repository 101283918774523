import { useAuth0 } from '@auth0/auth0-react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import ApiProvider from '../providers/ApiProvider';

export type UserLearningPath = {
  id: number;
  userId: string;
  learningPath_ID: number;
  learningPath: LearningPath;
  isPrimaryLearningPath: boolean;
  startedLearningPath: string;
  progressPercentage: number;
  completedLearningPath: string;
};

export type LearningPath = {
  id: number;
  name: string;
  requiresCompletionToCertify: boolean;
  requiresAssesmentToCertify: boolean;
  assesmentAsset: string;
  pathIsPublic: boolean;
  learningPathDescription: string;
  certificate_ID: number;
  certificate: string;
};

export default function useActiveLearningPaths(): UseQueryResult<UserLearningPath[], unknown> {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery(['activeLearningPaths'], async () => {
    const accessToken = await getAccessTokenSilently();

    const { data } = await ApiProvider.get('/learningpath/GetMyActiveLearningPaths', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  });
}
