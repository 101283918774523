import { Alert, AlertTitle, Card, CardActions, CardContent, Grid, Link, Stack, Typography, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import LoadingButton from '@mui/lab/LoadingButton';
import useActiveLearningPaths from '../hooks/UseActiveLearningPaths';
import useCompletedLearningPaths from '../hooks/UseCompletedLearningPaths';
import useInactiveLearningPaths from '../hooks/UseInactiveLearningPaths';
import useLearningPathDetail from '../hooks/UseLearningPathDetail';
import { useLeaveLearningPath, useJoinLearningPath, useChangePrimaryLearningPath } from '../hooks/UseLearningPathMutation';
import usePrimaryLearningPathDetail from '../hooks/UsePrimaryLearningPathDetail';
import { getAssetUrl } from '../helpers/AssetUrlHelper';
import LearningPathsTable from '../components/LearningPaths/LearningPathTable';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { PageMeta } from '../components/PageMeta/PageMeta';
import { useConfirm } from 'material-ui-confirm';
import StarBorderIcon from '@mui/icons-material/StarBorder';

const LearningPathsPage: React.FC = () => {
  const { data: primaryLearningPathDetailData } = usePrimaryLearningPathDetail();
  const { data: learningPathDetailData } = useLearningPathDetail();
  const { data: activeLearningPathsData } = useActiveLearningPaths();
  const { data: inactiveLearningPathsData } = useInactiveLearningPaths();
  const { data: completedLearningPathsData } = useCompletedLearningPaths();
  const { isLoading: isJoinLearningPathLoading, mutate: joinLearningPath } = useJoinLearningPath();
  const { isLoading: isLeaveLearningPathLoading, mutate: leaveLearningPath } = useLeaveLearningPath();
  const { isLoading: isChangePrimaryLearningPath, mutate: changePrimaryLearningPath } = useChangePrimaryLearningPath();
  const theme = useTheme();
  const confirm = useConfirm();

  const slugifyText = (input: string) => input?.replace(/[\W_]/g, '-')?.toLowerCase();

  const handleLeaveLearningPath = (learningPathId: number, learningPathName: string) => {
    confirm({ description: `Are you sure you want to leave learning path, ${learningPathName}?` })
      .then(() => {
        leaveLearningPath(learningPathId);
      })
      .catch(() => {});
  };

  return (
    <>
      <PageMeta
        title={`Learning Paths on Empowering.Cloud`}
        description={`Learning Paths on Empowering.Cloud`}
        openGraphTitle={`Learning Paths on Empowering.Cloud`}
        openGraphDescription={`Learning Paths on Empowering.Cloud`}
      />

      <Typography variant="h1" textAlign="center" fontWeight={300} sx={{ pb: 2 }} textTransform="uppercase">
        Your Primary Learning Path
      </Typography>
      {learningPathDetailData && learningPathDetailData.noLearningPathSet ? (
        <Alert severity="info">
          <AlertTitle>No Primary Learning Path</AlertTitle>
          You don't have a primary learning paths set up — <strong>yet!</strong>
        </Alert>
      ) : (
        <Alert severity="success">
          <AlertTitle>Learning Path {learningPathDetailData?.primaryLearningPathName}</AlertTitle>
          <Stack direction="column" spacing={1}>
            <span>{learningPathDetailData?.primaryLearningPathCompletionPercentage} % complete. </span>
            {learningPathDetailData && learningPathDetailData.asset && (
              <span>
                Next:{' '}
                <Link
                  component={RouterLink}
                  color={theme.palette.success.dark}
                  to={getAssetUrl(
                    learningPathDetailData?.asset.id.toString(),
                    learningPathDetailData?.asset?.embedUrl,
                    learningPathDetailData?.asset.assetUrl,
                    learningPathDetailData?.asset.assetType.name,
                  )}
                >
                  <strong>{learningPathDetailData?.asset?.displayName}</strong>
                </Link>
              </span>
            )}
          </Stack>
        </Alert>
      )}

      {primaryLearningPathDetailData && <LearningPathsTable learningPath={primaryLearningPathDetailData} />}

      <Typography variant="h5" textAlign="center" fontWeight={300} sx={{ py: 2 }} textTransform="uppercase">
        Your Joined Learning Paths
      </Typography>

      <Grid container spacing={2} alignItems="stretch">
        {activeLearningPathsData &&
          activeLearningPathsData.map((learningPath, index: Number) => (
            <Grid item xs={12} md={4} key={`learning-path-${index}`} sx={{ display: 'flex' }}>
              <Card variant="outlined" sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                <CardContent>
                  <Link
                    component={RouterLink}
                    color={theme.palette.secondary.dark}
                    to={`/learningpaths/${learningPath?.learningPath?.id}/${slugifyText(learningPath?.learningPath?.name)}`}
                    title={learningPath.learningPath?.name}
                  >
                    <Typography variant="h6">{learningPath.learningPath.name}</Typography>
                  </Link>
                  <Typography variant="body1">{learningPath.learningPath.learningPathDescription}</Typography>

                  <Stack sx={{ mt: 2 }}>
                    <Typography variant="body2">{learningPath.progressPercentage}% Complete</Typography>
                    <Typography variant="body2">Started: {moment(learningPath.startedLearningPath).format('MMMM Do YYYY')}</Typography>
                  </Stack>
                </CardContent>
                <CardActions>
                  <LoadingButton
                    onClick={() => handleLeaveLearningPath(learningPath.learningPath.id, learningPath.learningPath.name)}
                    endIcon={<ExitToAppIcon />}
                    loading={isLeaveLearningPathLoading}
                    loadingPosition="end"
                    variant="contained"
                    size="small"
                  >
                    Leave this path
                  </LoadingButton>
                  {!learningPath.isPrimaryLearningPath && (
                    <LoadingButton
                      onClick={() => changePrimaryLearningPath(learningPath.learningPath.id)}
                      endIcon={<StarBorderIcon />}
                      loading={isChangePrimaryLearningPath}
                      loadingPosition="end"
                      variant="contained"
                      size="small"
                    >
                      Make Primary
                    </LoadingButton>
                  )}
                </CardActions>
              </Card>
            </Grid>
          ))}
      </Grid>
      {activeLearningPathsData && activeLearningPathsData.length === 0 && (
        <Alert severity="info">
          <AlertTitle>No Learning Paths</AlertTitle>
          You have not joined any learning paths
        </Alert>
      )}

      <Typography variant="h5" textAlign="center" fontWeight={300} sx={{ py: 2 }} textTransform="uppercase">
        All Learning Paths
      </Typography>

      <Grid container spacing={2} alignItems="stretch">
        {inactiveLearningPathsData &&
          inactiveLearningPathsData.map((learningPath, index) => (
            <Grid item xs={12} md={4} key={index} sx={{ display: 'flex' }}>
              <Card
                variant="outlined"
                key={`inactive-learning-path-${learningPath.id}`}
                sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}
              >
                <CardContent>
                  <Link
                    component={RouterLink}
                    color={theme.palette.secondary.dark}
                    to={`/learningpaths/${learningPath?.id}/${slugifyText(learningPath?.name)}`}
                    title={learningPath?.name}
                  >
                    <Typography variant="h6">{learningPath.name}</Typography>
                  </Link>
                  <Typography variant="body1">{learningPath.learningPathDescription}</Typography>
                </CardContent>
                <CardActions>
                  <LoadingButton
                    onClick={() => joinLearningPath(learningPath.id)}
                    endIcon={<HowToRegIcon />}
                    loading={isJoinLearningPathLoading}
                    loadingPosition="end"
                    variant="contained"
                    size="small"
                  >
                    Join this path
                  </LoadingButton>
                </CardActions>
              </Card>
            </Grid>
          ))}
      </Grid>
      {inactiveLearningPathsData && inactiveLearningPathsData.length === 0 && (
        <Alert severity="info">
          <AlertTitle>No Learning Paths</AlertTitle>
          There are no other learning paths to show you right now.
        </Alert>
      )}

      {completedLearningPathsData && completedLearningPathsData.length > 0 && (
        <>
          <Typography variant="h5" textAlign="center" fontWeight={300} sx={{ py: 2 }} textTransform="uppercase">
            Completed Learning Paths
          </Typography>
          {completedLearningPathsData.map(learningPath => (
            <Card variant="outlined" key={`completed-learning-path-${learningPath.id}`}>
              <CardContent>
                <Stack>
                  <Link
                    component={RouterLink}
                    color={theme.palette.secondary.dark}
                    to={`/learningpaths/${learningPath?.learningPath?.id}/${slugifyText(learningPath?.learningPath?.name)}`}
                    title={learningPath?.learningPath?.name}
                  >
                    <Typography>{learningPath.learningPath.name}</Typography>
                  </Link>
                </Stack>
              </CardContent>
            </Card>
          ))}
        </>
      )}
    </>
  );
};

export default LearningPathsPage;
