import { useAuth0 } from '@auth0/auth0-react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import ApiProvider from '../providers/ApiProvider';
import { Asset } from './UseAsset';

export default function UseQuizDetail(quizId: string | undefined): UseQueryResult<Asset, unknown> {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery(
    ['quizDetail', quizId],
    async () => {
      const accessToken = await getAccessTokenSilently();

      const { data } = await ApiProvider.get(`/quiz/GetQuiz?quizId=${quizId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return data;
    },
    { enabled: !!quizId },
  );
}
