import { CardContent, Typography, Box, Chip, Grid, Link as MuiLink } from '@mui/material';
import { useTranslation } from 'react-i18next';
import GroupsIcon from '@mui/icons-material/Groups';
import { AssetCardActionAreaWithStyles, AssetCardFooterWithStyles, AssetCardMediaWithStyles, AssetCardWithStyles } from './AssetCard.styles';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import UserAvatar from '../../Auth/UserAvatar';
import VideocamIcon from '@mui/icons-material/Videocam';
import QuizIcon from '@mui/icons-material/Quiz';
import { color } from '@mui/system';

export type AssetCardProps = {
  ID: number;
  DisplayName: string;
  Author?: string;
  AuthorPictureUrl?: string;
  AuthorUrl?: string;
  Subtitle?: string;
  Type?: string;
  Category?: string;
  ThumbnailUrl: string;
  ThumbnailVariant?: 'rounded' | undefined;
  ThumbnailFallbackComponent?: React.ElementType;
  LengthMins?: number;
  Link: string;
  LastModified?: string;
  Size?: 'sm' | undefined;
  FakeDisabled?: boolean | undefined;
};

const AssetCard = (props: AssetCardProps) => {
  const {
    ID,
    DisplayName,
    Subtitle,
    Category,
    Author,
    AuthorPictureUrl,
    AuthorUrl,
    Type,
    LengthMins,
    Link,
    LastModified,
    Size,
    ThumbnailUrl,
    ThumbnailVariant,
    ThumbnailFallbackComponent,
    FakeDisabled,
  } = props;
  const { t } = useTranslation(['common']);

  const getAssetIcon = (type: string) => {
    switch (type) {
      case 'Video':
        return <VideocamIcon />;
      case 'Quiz':
        return <QuizIcon />;
      default:
        return <VideocamIcon />;
    }
  };

  return (
    <AssetCardWithStyles key={`search-${ID}`} variant="outlined">
      <AssetCardActionAreaWithStyles component={RouterLink} to={Link}>
        {ThumbnailUrl && <AssetCardMediaWithStyles component="img" image={ThumbnailUrl} size={Size} variant={ThumbnailVariant} />}
        {!ThumbnailUrl && ThumbnailFallbackComponent && <ThumbnailFallbackComponent />}
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'start',
            height: '100%',
            width: '100%',
            paddingX: { xs: 0, md: 2 },
            paddingY: { xs: 0, md: 1 },
            paddingTop: { xs: 1 },
          }}
        >
          <Grid container>
            <Grid item xs={12} md={10} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
              <Typography component="div" variant="h5" style={FakeDisabled ? { color: 'gray' } : {}}>
                {DisplayName}
              </Typography>
              <Typography variant="body2" color="text.secondary" component="div" style={FakeDisabled ? { color: 'gray' } : {}}>
                {Subtitle}
              </Typography>
              {Type && <Chip sx={{ mt: 1, p: 1, mr: 1 }} icon={getAssetIcon(Type)} label={Type} color="primary" />}
              {Category && <Chip sx={{ mt: 1, p: 1 }} icon={<GroupsIcon />} label={Category} />}
              {LengthMins && (
                <Typography variant="subtitle2" sx={{ display: { md: 'none' }, pt: 1 }}>
                  <>{LengthMins} minutes</>
                </Typography>
              )}
            </Grid>
            {LengthMins && (
              <Grid item sx={{ marginLeft: 'auto', display: { xs: 'none', md: 'block' } }}>
                <Typography variant="subtitle2" sx={{ pt: 1 }}>
                  <>{LengthMins} minutes</>
                </Typography>
              </Grid>
            )}
          </Grid>

          {(Author || LastModified) && (
            <AssetCardFooterWithStyles>
              {Author && (
                <>
                  <Box>
                    <RouterLink to={`/author/${AuthorUrl}`} style={{ textDecoration: 'none' }}>
                      <UserAvatar authorName={Author} authorImageSrc={AuthorPictureUrl} />
                    </RouterLink>
                  </Box>
                  <Box sx={{ pl: 1 }}>
                    <Typography variant="overline" sx={{ lineHeight: '1rem' }}>
                      {t('common:author')}
                    </Typography>
                    <MuiLink component={RouterLink} to={`/author/${AuthorUrl}`}>
                      <Typography variant="subtitle2" fontWeight={700}>
                        {Author}
                      </Typography>
                    </MuiLink>
                  </Box>
                </>
              )}
              {LastModified && (
                <Box sx={{ pl: 1, marginLeft: 'auto' }}>
                  <Typography variant="body2">
                    {t('common:lastmodified')}: {moment(LastModified).utc().format('MMMM Do YYYY')}
                  </Typography>
                </Box>
              )}
            </AssetCardFooterWithStyles>
          )}
        </CardContent>
      </AssetCardActionAreaWithStyles>
    </AssetCardWithStyles>
  );
};

export default AssetCard;
