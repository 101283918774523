import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

export interface ECCardVerticalProps {
  title: string;
  subtitle: string;
  imageUrl: string;
  imageAlt?: string;
  link?: string;
  cta?: {
    label: string;
    url: string;
  };
}

export const ECCardVertical = (props: ECCardVerticalProps) => {
  const { title, subtitle, imageUrl, imageAlt, cta, link } = props;

  return (
    <React.Fragment>
      <Card sx={{ height: '100%' }} draggable={false}>
        <CardActionArea
          component={Link}
          to={link ? link : '#'}
          sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start' }}
        >
          <CardMedia component="img" image={imageUrl} alt={imageAlt} />
          <CardContent sx={{ width: '100%' }}>
            <Typography gutterBottom variant="h6" component="div" sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
              {title}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
              {subtitle}
            </Typography>
          </CardContent>
          {cta && (
            <CardActions sx={{ justifyContent: 'center' }}>
              <Button variant="contained" href={cta?.url}>
                {cta?.label}
              </Button>
            </CardActions>
          )}
        </CardActionArea>
      </Card>
    </React.Fragment>
  );
};
