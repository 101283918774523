import { useQuery, UseQueryResult } from '@tanstack/react-query';
import ApiProvider from '../providers/ApiProvider';
import { useAuth0 } from '@auth0/auth0-react';
import { AxiosRequestHeaders } from 'axios';

export type ReportBasic = {
  reportName: string;
  thumbnailUrl: string;
  reportShortDescription: string;
  isComingSoon: boolean;
  canBeShown: boolean;
  order: number;
  urlSlug: string;
};

export default function useResearchReports(): UseQueryResult<ReportBasic[], unknown> {
  const { getAccessTokenSilently, user } = useAuth0();

  return useQuery(['research-reports'], async () => {
    const headers: AxiosRequestHeaders = {};
    if (user) {
      const accessToken = await getAccessTokenSilently();
      headers.Authorization = `Bearer ${accessToken}`;
    }

    const url = `/research/GetReports`;
    const { data } = await ApiProvider.get(url, {
      headers: headers,
    });

    return data;
  });
}
