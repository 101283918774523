import { Navigate, Route } from 'react-router-dom';
import { Routes as RouterRoutes } from 'react-router-dom';
import AsyncComponent from '../components/AsyncComponent';
import ProtectedComponent from '../components/Auth/ProtectedComponent';
import AccountManagementPage from '../pages/AccountManagementPage';
import BenefactorsPage from '../pages/BenefactorsPage';
import BriefingsPage from '../pages/BriefingsPage';
import DashboardPage from '../pages/DashboardPage';
import FirstRunPage from '../pages/FirstRunPage';
import LearningPathsPage from '../pages/LearningPathsPage';
import MyECPage from '../pages/MyECPage';
import NotFoundPage from '../pages/NotFoundPage';
import QAndAPage from '../pages/QAndAPage';
import ResearchReportsPage from '../pages/ResearchReportsPage';
import ResearchReportPage from '../pages/ResearchReportPage';
import QuizDetailPage from '../pages/QuizDetailPage';
import QuizListPage from '../pages/QuizListPage';
import SupportPage from '../pages/SupportPage';
import VideoPage from '../pages/VideoPage';
import TeamsUserSyncPage from '../pages/TeamsUserSyncPage';
import LoginPage from '../pages/LoginPage';
import AuthorPage from '../pages/AuthorPage';
import AuthorDetailPage from '../pages/AuthorDetailPage';
import LearningPathDetailPage from '../pages/LearningPathDetailPage';
import ManagerReportPage from '../pages/ManagerReportPage';

const Routes: React.FC = () => {
  return (
    <RouterRoutes>
      <Route path="*" element={<Navigate to="/404" replace />} />
      <Route
        path="/"
        element={
          <AsyncComponent>
            <DashboardPage />
          </AsyncComponent>
        }
      />
      <Route path="/sponsors" element={<BenefactorsPage />} />
      <Route
        path="/briefings"
        element={
          <AsyncComponent>
            <BriefingsPage />
          </AsyncComponent>
        }
      />
      <Route
        path="/briefings/:videoId/:videoDescription"
        element={
          <AsyncComponent>
            <VideoPage />
          </AsyncComponent>
        }
      />
      <Route
        path="/quizzes"
        element={
          <ProtectedComponent async>
            <QuizListPage />
          </ProtectedComponent>
        }
      />
      <Route
        path="/quizzes/:quizId/:quizDescription"
        element={
          <ProtectedComponent async>
            <QuizDetailPage />
          </ProtectedComponent>
        }
      />
      <Route
        path="/learningpaths"
        element={
          <ProtectedComponent async>
            <LearningPathsPage />
          </ProtectedComponent>
        }
      />
      <Route
        path="/learningpaths/:learningPathId/:learningPathDescription"
        element={
          <ProtectedComponent async>
            <LearningPathDetailPage />
          </ProtectedComponent>
        }
      />
      <Route
        path="/enablement"
        element={
          <AsyncComponent>
            <QAndAPage />
          </AsyncComponent>
        }
      />
      <Route
        path="/reports"
        element={
          <AsyncComponent>
            <ManagerReportPage />
          </AsyncComponent>
        }
      />
      <Route
        path="/research"
        element={
          <AsyncComponent>
            <ResearchReportsPage />
          </AsyncComponent>
        }
      />
      {/* <Route
        path="/research/:reportSlug"
        element={
          <AsyncComponent>
            <ResearchReportPage />
          </AsyncComponent>
        }
      /> */}
      <Route
        path="/author"
        element={
          <AsyncComponent>
            <AuthorPage />
          </AsyncComponent>
        }
      />
      <Route
        path="/author/:authorSlug"
        element={
          <AsyncComponent>
            <AuthorDetailPage />
          </AsyncComponent>
        }
      />
      <Route path="qanda" element={<Navigate to="/enablement" />} />
      <Route path="premium" element={<Navigate to="/enablement" />} />

      <Route
        path="/account"
        element={
          <ProtectedComponent>
            <AccountManagementPage />
          </ProtectedComponent>
        }
      />
      <Route
        path="/myec"
        element={
          <ProtectedComponent async>
            <MyECPage />
          </ProtectedComponent>
        }
      />
      <Route
        path="/support"
        element={
          <ProtectedComponent>
            <SupportPage />
          </ProtectedComponent>
        }
      />
      <Route
        path="/firstrun"
        element={
          <ProtectedComponent>
            <FirstRunPage />
          </ProtectedComponent>
        }
      />
      <Route
        path="/teamsusersync/:u"
        element={
          <ProtectedComponent>
            <TeamsUserSyncPage />
          </ProtectedComponent>
        }
      />
      <Route path="/404" element={<NotFoundPage />} />
      <Route path="/login" element={<LoginPage />} />
    </RouterRoutes>
  );
};

export default Routes;
