import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import { Divider, Toolbar } from '@mui/material';
import ECNavigationBar from './Navigation/ECNavigationBar';
import ECBreadcrumbs from './Breadcrumbs/ECBreadcrumbs';

const NavBar = () => {
  return (
    <AppBar component="nav" position="static" sx={{ boxShadow: 'none', backgroundColor: '#ffffff' }}>
      <Container maxWidth="xl" sx={{ display: { xs: 'none', md: 'block' } }}>
        <Toolbar disableGutters sx={{ justifyContent: 'center' }}>
          <ECNavigationBar />
        </Toolbar>
        <Divider />
      </Container>
      <Container maxWidth="xl" sx={{ paddingBottom: 2, paddingTop: 2 }}>
        <ECBreadcrumbs />
      </Container>
    </AppBar>
  );
};
export default NavBar;
