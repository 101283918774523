import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import UserAvatar, { UserAvatarProps } from '../components/Auth/UserAvatar';
import AssetCard from '../components/Cards/AssetCard/AssetCard';
import { PageMeta } from '../components/PageMeta/PageMeta';
import useAuthors, { AuthorBasic } from '../hooks/UseAuthors';

const AuthorPage: React.FC = () => {
  const { t: translate } = useTranslation(['navigation']);
  const { data } = useAuthors();

  return (
    <>
      <PageMeta
        title={`${translate('navigation:author')} on Empowering.Cloud`}
        description={`${translate('navigation:author')} on Empowering.Cloud`}
        openGraphTitle={`${translate('navigation:author')} on Empowering.Cloud`}
        openGraphDescription={`${translate('navigation:author')} on Empowering.Cloud`}
      />
      <Typography variant="h1" textAlign="center" fontWeight={300} sx={{ pb: 2 }} textTransform="uppercase">
        Empowering.Cloud Authors
      </Typography>
      {data && (
        <Grid container spacing={2}>
          {data.map((author: AuthorBasic, index: number) => (
            <Grid item xs={12} md={6} lg={4} key={`author-${index}`}>
              <AssetCard
                ID={index}
                Size="sm"
                DisplayName={author.name}
                Subtitle={author.shortDescription}
                ThumbnailUrl={author.pictureUrl}
                ThumbnailVariant="rounded"
                ThumbnailFallbackComponent={(props: UserAvatarProps) => (
                  <UserAvatar variant="rounded" size="lg" {...props} authorName={author.name} authorImageSrc={author.pictureUrl} />
                )}
                Link={`/author/${author.urlSlug}`}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default AuthorPage;
