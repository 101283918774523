import { useQuery, UseQueryResult } from '@tanstack/react-query';
import ApiProvider from '../providers/ApiProvider';
import { Asset } from './UseAsset';

const getRecentAssets = async (): Promise<Asset[]> => {
  const { data } = await ApiProvider.get('/asset/GetRecentAssets?count=5');
  return data;
};

export default function useRecentAssets(): UseQueryResult<Asset[], unknown> {
  return useQuery(['recentAssets'], getRecentAssets);
}
