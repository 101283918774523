import { useAuth0 } from '@auth0/auth0-react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import ApiProvider from '../providers/ApiProvider';
import { UserLearningPath } from './UseActiveLearningPaths';

export default function useCompletedLearningPaths(): UseQueryResult<UserLearningPath[], unknown> {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery(['completedLearningPaths'], async () => {
    const accessToken = await getAccessTokenSilently();

    const { data } = await ApiProvider.get('/learningpath/GetCompletedLearningPaths', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  });
}
