import { useAuth0 } from '@auth0/auth0-react';
import { LinearProgress } from '@mui/material';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { ConfirmProvider } from 'material-ui-confirm';
import { ErrorBoundary } from 'react-error-boundary';
import ExternalProviderSetupComponent from './components/ExternalProviders/ExternalProviderSetupComponent';
import ErrorComponent from './components/Generic/ErrorComponent';
import useFirstRun from './hooks/UseFirstRun';
import Head from './layout/Head';
import Layout from './layout/Layout';
import NewUserRoutes from './layout/NewUserRoutes';
import Routes from './layout/Routes';

function App() {
  const { isLoading } = useAuth0();
  const shouldDisplayFirstRun = useFirstRun();

  if (isLoading) {
    return (
      <Layout>
        <Head />
        <LinearProgress />
      </Layout>
    );
  }

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <Layout>
          <ConfirmProvider>
            <Head />
            <ErrorBoundary
              fallbackRender={({ error, resetErrorBoundary }) => <ErrorComponent error={error} resetErrorBoundary={resetErrorBoundary} />}
              onReset={reset}
            >
              <ExternalProviderSetupComponent />
              {shouldDisplayFirstRun ? <NewUserRoutes /> : <Routes />}
            </ErrorBoundary>
          </ConfirmProvider>
        </Layout>
      )}
    </QueryErrorResetBoundary>
  );
}

export default App;
