import { Route, Navigate, Routes as RouterRoutes } from 'react-router-dom';
import ProtectedComponent from '../components/Auth/ProtectedComponent';
import FirstRunPage from '../pages/FirstRunPage';

const NewUserRoutes: React.FC = () => {
  return (
    <RouterRoutes>
      <Route path="*" element={<Navigate to="/" replace />} />
      <Route path="/" element={<Navigate to="/firstrun" replace />} />
      <Route
        path="/firstrun"
        element={
          <ProtectedComponent>
            <FirstRunPage />
          </ProtectedComponent>
        }
      />
    </RouterRoutes>
  );
};

export default NewUserRoutes;
