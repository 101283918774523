import { useQuery, UseQueryResult } from '@tanstack/react-query';
import ApiProvider from '../providers/ApiProvider';
import { Asset } from './UseAsset';

export type AuthorDetailed = {
  name: string;
  pictureUrl: string;
  urlSlug: string;
  blogLink: string;
  linkedInLink: string;
  shortDescription: string;
  longDescription: string;
  masterdonLink: string;
  mvpLink: string;
  mvpid: string;
  otherLink: string;
  twitterLink: string;
  youTubeLink: string;
  assets: Asset[];
};

const getAuthor = async (authorSlug: string | undefined): Promise<AuthorDetailed> => {
  const { data } = await ApiProvider.get(`/author/${authorSlug}`);
  return data;
};

export default function useAuthor(authorSlug: string | undefined): UseQueryResult<AuthorDetailed, unknown> {
  return useQuery(['author', authorSlug], () => getAuthor(authorSlug), { enabled: !!authorSlug });
}
