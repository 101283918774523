import * as React from 'react';
import { AppBar, Avatar, Box, Container, Menu, MenuItem, Toolbar, Tooltip, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import MobileNavigation from '../Navigation/MobileNavigation';
import HeaderLogo from '../../styles/eclogo.png';
import { useAuth0 } from '@auth0/auth0-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IconButtonWithStyles } from './Header.styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import useFirstRun from '../../hooks/UseFirstRun';
import { ECSearch } from '../Search/ECSearch';
import UseMyDetails from '../../hooks/UseMyDetails';

const Header = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [searchOpen, setSearchOpen] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const { isLoading, isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const shouldDisplayFirstRun = useFirstRun();
  const { data } = UseMyDetails();

  const loggedInSettings = [
    {
      name: 'My EC',
      action: () => {
        handleCloseUserMenu();
        navigate('/myec');
      },
    },
    {
      name: 'Account Management',
      action: () => {
        handleCloseUserMenu();
        navigate('/account');
      },
    },
    {
      name: 'Support',
      action: () => {
        handleCloseUserMenu();
        navigate('/support');
      },
    },
    {
      name: 'Logout',
      action: () => {
        handleCloseUserMenu();
        logout({ logoutParams: { returnTo: window.location.origin } });
      },
    },
  ];

  const noAuthSettings = [
    {
      name: 'Login / Signup',
      action: () => {
        handleCloseUserMenu();
        loginWithRedirect({ appState: { returnTo: location.pathname } });
      },
    },
  ];

  const firstRunSettings = [
    {
      name: 'Logout',
      action: () => {
        handleCloseUserMenu();
        logout({ logoutParams: { returnTo: window.location.origin } });
      },
    },
  ];

  const managerSettings = [
    {
      name: 'Reports',
      action: () => {
        handleCloseUserMenu();
        navigate('/reports');
      },
    },
  ];

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSearchClickOpen = () => {
    setSearchOpen(true);
  };

  const handleSearchClickClose = () => {
    setSearchOpen(false);
  };

  const mobileNavigationProps = {
    handleDrawerToggle,
    mobileOpen,
  };

  return (
    <>
      <AppBar position="static" sx={{ boxShadow: 'none' }} id="header" data-skip-link="Go to Header">
        <Container maxWidth="xl">
          <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
            <Box
              sx={{
                display: { xs: 'flex' },
              }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { md: 'none', color: '#FFFFFF' } }}
                disabled={shouldDisplayFirstRun}
              >
                <MenuIcon />
              </IconButton>
              <Link to="/">
                <Box
                  component="img"
                  sx={{
                    height: 70,
                    display: { xs: 'flex' },
                    mr: 1,
                    py: 1,
                  }}
                  alt="Empowering Cloud Logo"
                  src={HeaderLogo}
                />
              </Link>
            </Box>
            <Box>
              <Box sx={{ display: 'flex' }}>
                <Tooltip title="Open search">
                  <IconButtonWithStyles aria-label="Search" onClick={handleSearchClickOpen} disabled={shouldDisplayFirstRun}>
                    <SearchIcon sx={{ color: '#ffffff', fontSize: 48 }} />
                  </IconButtonWithStyles>
                </Tooltip>
                {/* <Tooltip title="Open notifications">
                  <IconButtonWithStyles className="beamerTrigger" aria-label="Notifications" disabled={shouldDisplayFirstRun}>
                    <CircleNotificationsIcon sx={{ color: '#ffffff', fontSize: 48 }} />
                  </IconButtonWithStyles>
                </Tooltip> */}
                <Tooltip title="Open settings" sx={{ display: 'block' }}>
                  <IconButtonWithStyles onClick={handleOpenUserMenu} aria-label="Settings" sx={{ width: '64px' }}>
                    {!isLoading && isAuthenticated && <Avatar src={user?.picture} alt="Avatar" />}
                    {!isLoading && !isAuthenticated && <AccountCircleIcon sx={{ color: '#ffffff', fontSize: 48 }} />}
                  </IconButtonWithStyles>
                </Tooltip>
              </Box>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {!isLoading &&
                  isAuthenticated &&
                  !shouldDisplayFirstRun &&
                  data?.role === 'Manager' &&
                  data?.planName.includes('Enablement') &&
                  managerSettings.map((setting, index) => (
                    <MenuItem key={index} onClick={setting.action}>
                      <Typography textAlign="center">{setting.name}</Typography>
                    </MenuItem>
                  ))}

                {!isLoading &&
                  isAuthenticated &&
                  shouldDisplayFirstRun &&
                  firstRunSettings.map((setting, index) => (
                    <MenuItem key={index} onClick={setting.action}>
                      <Typography textAlign="center">{setting.name}</Typography>
                    </MenuItem>
                  ))}
                {!isLoading &&
                  isAuthenticated &&
                  !shouldDisplayFirstRun &&
                  loggedInSettings.map((setting, index) => (
                    <MenuItem key={index} onClick={setting.action}>
                      <Typography textAlign="center">{setting.name}</Typography>
                    </MenuItem>
                  ))}
                {!isLoading &&
                  !isAuthenticated &&
                  noAuthSettings.map((setting, index) => (
                    <MenuItem key={index} onClick={setting.action}>
                      <Typography textAlign="center">{setting.name}</Typography>
                    </MenuItem>
                  ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <MobileNavigation {...mobileNavigationProps} />
      <ECSearch isSearchOpen={searchOpen} handleClose={handleSearchClickClose} />
    </>
  );
};
export default Header;
