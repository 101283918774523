import { useQuery, UseQueryResult } from '@tanstack/react-query';
import ApiProvider from '../providers/ApiProvider';
import { useAuth0 } from '@auth0/auth0-react';
import { AxiosRequestHeaders } from 'axios';

export type MyDetailsModel = {
  planId: string;
  planName: string;
  role: string;
};

export default function UseMyDetails(): UseQueryResult<MyDetailsModel, unknown> {
  const { getAccessTokenSilently, user } = useAuth0();

  return useQuery(['get-my-details'], async () => {
    const headers: AxiosRequestHeaders = {};
    if (user == null) {
      console.log("User is null");
      return {
        planID: "",
        planName: "",
        role: "none"
      };
    }

    if (user) {
      const accessToken = user != null ? await getAccessTokenSilently() : null;
      headers.Authorization = `Bearer ${accessToken}`;
    }

    const url = `/me/getMyDetails`;
    const { data } = await ApiProvider.get(url, {
      headers: headers,
    });

    return data;
  });
}
