import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import ApiProvider from '../providers/ApiProvider';

export const useJoinLearningPath = () => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    async function (learningPathId: number) {
      const accessToken = await getAccessTokenSilently();
      return await ApiProvider.put(
        `/learningpath/JoinLearningPath?learningPathId=${learningPathId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['learningPathDetail']);
        queryClient.invalidateQueries(['primaryLearningPathDetail']);
        queryClient.invalidateQueries(['inactiveLearningPaths']);
        queryClient.invalidateQueries(['learningPathDetail']);
        queryClient.invalidateQueries(['activeLearningPaths']);
        queryClient.invalidateQueries(['learningPathIndividualDetail']);
      },
    },
  );
};

export const useLeaveLearningPath = () => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    async function (learningPathId: number) {
      const accessToken = await getAccessTokenSilently();
      return await ApiProvider.put(
        `/learningpath/LeaveLearningPath?learningPathId=${learningPathId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['learningPathDetail']);
        queryClient.invalidateQueries(['primaryLearningPathDetail']);
        queryClient.invalidateQueries(['inactiveLearningPaths']);
        queryClient.invalidateQueries(['learningPathDetail']);
        queryClient.invalidateQueries(['activeLearningPaths']);
        queryClient.invalidateQueries(['learningPathIndividualDetail']);
      },
    },
  );
};

export const useChangePrimaryLearningPath = () => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation(
    async function (learningPathId: number) {
      const accessToken = await getAccessTokenSilently();
      return await ApiProvider.put(
        `/learningpath/SetPrimaryLearningPath?learningPathId=${learningPathId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['learningPathDetail']);
        queryClient.invalidateQueries(['primaryLearningPathDetail']);
        queryClient.invalidateQueries(['inactiveLearningPaths']);
        queryClient.invalidateQueries(['learningPathDetail']);
        queryClient.invalidateQueries(['activeLearningPaths']);
      },
    },
  );
};
