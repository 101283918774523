import { useAuth0 } from '@auth0/auth0-react';
import useReadLocalStorage from './UseReadLocalStorage';

export default function useFirstRun(): boolean {
  
  
    const { user } = useAuth0();
    const firstRunLocalStorage = useReadLocalStorage('firstRunComplete');

    if (user == null) return false;

    const shouldDisplayFirstRunForDemoUser = firstRunLocalStorage == null && user && user.email === 'demo@empowering.cloud';
    const shouldDisplayFirstRunForAllUsers = firstRunLocalStorage == null && user && user['http://empowering.cloud/loginCount'] <= 1;
    const shouldDisplayFirstRun = shouldDisplayFirstRunForAllUsers || shouldDisplayFirstRunForDemoUser;
    const shouldDisplayForSpecificDomain = user && !user.email?.includes('avispl.com');

  return (shouldDisplayFirstRun && shouldDisplayForSpecificDomain) ?? false;
}
