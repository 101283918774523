import { Avatar } from '@mui/material';

export interface UserAvatarProps {
  authorName: string;
  authorImageSrc?: string;
  size?: 'sm' | 'md' | 'lg' | undefined;
  variant?: 'square' | 'rounded' | undefined;
}

const UserAvatar: React.FC<UserAvatarProps> = ({ authorName, authorImageSrc, size, variant }) => {
  const styles = () => {
    return {
      borderRadius: variant === 'square' ? 0 : 3,
      bgcolor: stringToColor(authorName),
      height: size === 'lg' ? 150 : 43,
      width: size === 'lg' ? 150 : 45,
      fontSize: size === 'lg' ? 36 : '1.25rem',
    };
  };

  return <Avatar variant={variant} sx={styles} alt={authorName} src={authorImageSrc} {...stringAvatar(authorName)} />;
};

function stringAvatar(name: string) {
  return {
    children: `${name?.split(' ')[0][0]}${name?.split(' ')[1][0]}`,
  };
}

function stringToColor(string: string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  if (color === '#8d8896') color = '#2f7040'; //Exclude this computed grey hex to avoid grey on grey.

  return color;
}

export default UserAvatar;
