import { useAuth0 } from '@auth0/auth0-react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosRequestHeaders } from 'axios';
import ApiProvider from '../providers/ApiProvider';

export type Author = {
  id: string;
  name: string;
  urlSlug: string;
  pictureUrl: string;
  shortDescription: string;
};

export type Category = {
  name: string;
};

export type Type = {
  name: string;
};

export type Asset = {
  id: number;
  displayName: string;
  assetType: Type;
  assetUrl: string;
  embedUrl: string;
  thumbnailUrl: string;
  lastModified: string;
  viewCount: number;
  isWatched: boolean;
  author: Author;
  lengthMins: number;
  category: Category;
  releaseDate: string;
  microsoftSkillsArea: string;
  subtitle: string;
  examRequirement: string;
  assetFreeTextDescriptionHtml: string;
  watchPercentage: number;
  weighting: string;
  assetTranscript: string;
  isComingSoon: boolean;
};

export default function useAsset(assetId: string | undefined): UseQueryResult<Asset, unknown> {
  const { getAccessTokenSilently, user } = useAuth0();

  return useQuery(
    ['asset', assetId],
    async () => {
      const headers: AxiosRequestHeaders = {};
      if (user) {
        const accessToken = await getAccessTokenSilently();
        headers.Authorization = `Bearer ${accessToken}`;
      }

      const url = `/asset/GetAssetById?assetId=${assetId}`;
      const { data } = await ApiProvider.get(url, {
        headers: headers,
      });

      return data;
    },
    { enabled: !!assetId },
  );
}
