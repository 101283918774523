import { useAuth0 } from '@auth0/auth0-react';
import { LinearProgress } from '@mui/material';
import React from 'react';
import { Suspense } from 'react';
import { NotLoggedIn } from './NotLoggedIn';

interface ProtectedComponentProps {
  async?: boolean;
}

const ProtectedComponent: React.FC<React.PropsWithChildren<ProtectedComponentProps>> = ({ children, async }) => {
  const { isLoading, isAuthenticated } = useAuth0();

  if (isLoading) {
    return <LinearProgress />;
  }

  if (async && isAuthenticated) {
    return <Suspense fallback={<LinearProgress />}>{children}</Suspense>;
  }

  if (isAuthenticated) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return <NotLoggedIn />;
};

export default ProtectedComponent;
