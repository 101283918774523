import { Box, Grid, Paper, Typography, useTheme } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Image } from 'mui-image';
import { PageMeta } from '../components/PageMeta/PageMeta';

const BenefactorsPage: React.FC = () => {
  const { palette } = useTheme();

  return (
    <>
      <PageMeta
        title={`Benefactors and Supporters on Empowering.Cloud`}
        description={`Our Benefactors and Supporters on Empowering.Cloud`}
        openGraphTitle={`Benefactors and Supporters on Empowering.Cloud`}
        openGraphDescription={`Benefactors and Supporters on Empowering.Cloud`}
      />
      <Box pb={5} textAlign="center">
        <Typography variant="h1" textAlign="center" fontWeight={300} sx={{ pb: 2 }} textTransform="uppercase">
          Our Sponsors
        </Typography>
        <Paper elevation={0} sx={{ p: 3 }}>
          <Typography variant="h3" fontWeight={700} sx={{ pb: 2 }}>
            These companies make this possible <FavoriteIcon sx={{ fontSize: 28, color: palette.primary.main }} />
          </Typography>
          <Typography variant="body1">
            Empowering.Cloud is able to offer the best Microsoft Teams and Microsoft 365 insights, advice and briefings to the community thanks to our sponsors.
          </Typography>
        </Paper>
      </Box>
      <Box pb={5} textAlign="center">
        <Paper elevation={0} sx={{ p: 3 }}>
          <Typography variant="h4" sx={{ pb: 1 }}>
            Benefactors
          </Typography>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={4}>
              <a href="https://www.audiocodes.com/solutions-products/solutions/microsoft-teams-solutions" target="_blank" rel="noreferrer">
                <Image src="https://empowering.cloud/wp-content/uploads/2022/06/audiocodes-logo_v2.png" alt="AudioCodes" fit="contain" duration={2000} />
              </a>
            </Grid>
            <Grid item xs={12} sm={4}>
              <a href="https://landistechnologies.com/microsoft-teams-contact-center" target="_blank" rel="noreferrer">
                <Image
                  src="https://empowering.cloud/wp-content/uploads/2023/06/Landis-Logo-Benefactor.png"
                  alt="Landis Technology"
                  fit="contain"
                  duration={2000}
                />
              </a>
            </Grid>

            <Grid item xs={12} md={4}>
              <a href="https://www.logitech.com/en-gb" target="_blank" rel="noreferrer">
                <Image src="https://empowering.cloud/wp-content/uploads/2024/04/Logitech-300x93-1.png" alt="Logitech" fit="contain" duration={2000} />
              </a>
            </Grid>

            <Grid item xs={12} sm={4}>
              <a href="https://neat.no/devices/" target="_blank" rel="noreferrer">
                <Image src="https://empowering.cloud/wp-content/uploads/2022/06/neat_purple_logo_crop.png" alt="Neat" fit="contain" duration={2000} />
              </a>
            </Grid>
            <Grid item xs={12} sm={4}>
              <a href="https://www.pure-ip.com/" target="_blank" rel="noreferrer">
                <Image src="https://empowering.cloud/wp-content/uploads/2024/01/PureIP-Logo-1800x961-1.png" alt="PureIP" fit="contain" duration={2000} />
              </a>
            </Grid>

            <Grid item xs={12} sm={4}>
              <a href="https://ribboncommunications.com/solutions/enterprise-solutions/" target="_blank" rel="noreferrer">
                <Image src="https://empowering.cloud/wp-content/uploads/2022/06/ribbon-logo-color.png" alt="Ribbon" fit="contain" duration={2000} />
              </a>
            </Grid>
          </Grid>
        </Paper>
      </Box>

      <Box pb={5} textAlign="center">
        <Paper elevation={0} sx={{ p: 3 }}>
          <Typography variant="h4" sx={{ pb: 1 }}>
            Patrons
          </Typography>
          <Grid container spacing={8} justifyContent="center" alignItems="center" direction="row" sx={{ pb: 4 }}>
            <Grid item xs={12} sm={4}>
              <a href="https://avispl.com/solutions/microsoft-teams/" target="_blank" rel="noreferrer">
                <Image src="https://empowering.cloud/wp-content/uploads/2023/06/AVI-SPL_logo-patron.png" alt="AVI-SPL" fit="contain" duration={2000} />
              </a>
            </Grid>
            <Grid item xs={12} sm={4}>
              <a href="https://www.crestron.com/" target="_blank" rel="noreferrer">
                <Image src="https://empowering.cloud/wp-content/uploads/2023/06/crestron-logo-patron.png" alt="Crestron" fit="contain" duration={2000} />
              </a>
            </Grid>
          </Grid>
          <Grid container spacing={8} justifyContent="center" alignItems="center" direction="row" sx={{ pb: 4 }}>
            <Grid item xs={12} sm={4}>
              <a href="https://www.jabra.co.uk/business/for-your-platform/microsoft" target="_blank" rel="noreferrer">
                <Image src="https://empowering.cloud/wp-content/uploads/2023/06/Jabra-Patron.jpg" alt="Jabra" fit="contain" duration={2000} />
              </a>
            </Grid>
            <Grid item xs={12} sm={4}>
              <a href="https://go.luware.com/empowering.cloud22-23" target="_blank" rel="noreferrer">
                <Image src="https://empowering.cloud/wp-content/uploads/2024/07/Luware-300x100-1.png" alt="Luware" fit="contain" duration={2000} />
              </a>
            </Grid>
            <Grid item xs={12} sm={4}>
              <a href="https://numonix.cloud/" target="_blank" rel="noreferrer">
                <Image src="https://empowering.cloud/wp-content/uploads/2023/06/Numonix-Patron.png" alt="Numonix" fit="contain" duration={2000} />
              </a>
            </Grid>
          </Grid>
        </Paper>
      </Box>

      <Box pb={5} textAlign="center">
        <Paper elevation={0} sx={{ p: 3 }}>
          <Typography variant="h4" sx={{ pb: 1 }}>
            Supporters
          </Typography>

          <Grid container spacing={8} justifyContent="center" alignItems="center" direction="row" sx={{ pb: 4 }}>
            <Grid item xs={4} md={2}>
              <a href="https://callroute.com/" target="_blank" rel="noreferrer">
                <Image src="https://empowering.cloud/wp-content/uploads/2024/01/Callroute-300x100-1.png" alt="Callroute" fit="contain" duration={2000} />
              </a>
            </Grid>
            <Grid item xs={4} md={2}>
              <a href="https://www.codesoftware.net/" target="_blank" rel="noreferrer">
                <Image src="https://empowering.cloud/wp-content/uploads/2022/06/CODE_logo_rgb_positive.png" alt="CODE" fit="contain" duration={2000} />
              </a>
            </Grid>

            <Grid item xs={4} md={2}>
              <a href="https://gomomentum.com/" target="_blank" rel="noreferrer">
                <Image src="https://empowering.cloud/wp-content/uploads/2024/03/Momentum-300x75-1.png" alt="Momentum" fit="contain" duration={2000} />
              </a>
            </Grid>
            <Grid item xs={4} md={2}>
              <a href="https://www.akixi.com/" target="_blank" rel="noreferrer">
                <Image src="https://empowering.cloud/wp-content/uploads/2024/05/Akixi-Logo-300x100-2.png" alt="Akixi" fit="contain" duration={2000} />
              </a>
            </Grid>
          </Grid>

          <Grid container spacing={8} justifyContent="center" alignItems="center" direction="row" sx={{ pb: 4 }}>
            <Grid item xs={4} md={2}>
              <a href="https://leap.expert/" target="_blank" rel="noreferrer">
                <Image src="https://empowering.cloud/wp-content/uploads/2022/10/LeapXpert_logo.png" alt="LeapXpert" fit="contain" duration={2000} />
              </a>
            </Grid>

            <Grid item xs={4} md={2}>
              <a href="https://teamsboss.com/" target="_blank" rel="noreferrer">
                <Image src="https://empowering.cloud/wp-content/uploads/2023/04/TeeamBoss.png" alt="TeamsBoss" fit="contain" duration={2000} />
              </a>
            </Grid>
            <Grid item xs={4} md={2}>
              <a href="https://nuwave.com/" target="_blank" rel="noreferrer">
                <Image
                  src="https://empowering.cloud/wp-content/uploads/2023/07/Nuwave-Powered-by-iPILOT-logo-002.png"
                  alt="NuWave"
                  fit="contain"
                  duration={2000}
                />
              </a>
            </Grid>
            <Grid item xs={4} md={2}>
              <a href="https://pexip.com/" target="_blank" rel="noreferrer">
                <Image src="https://empowering.cloud/wp-content/uploads/2023/10/Pexip-Logo-Blue-RGB-002.png" alt="Pexip" fit="contain" duration={2000} />
              </a>
            </Grid>
            <Grid item xs={4} md={2}>
              <a href="https://www.callcabinet.com/" target="_blank" rel="noreferrer">
                <Image
                  src="https://empowering.cloud/wp-content/uploads/2024/07/Callcabinet_Logo_Horizontal_WEB_Full_Color-002.png"
                  alt="CallCabinet"
                  fit="contain"
                  duration={2000}
                />
              </a>
            </Grid>
          </Grid>

          <Grid container spacing={8} justifyContent="center" alignItems="center" direction="row" sx={{ pb: 4 }}>
            <Grid item xs={4} md={2}>
              <a href="https://www.kurmi-software.com/" target="_blank" rel="noreferrer">
                <Image
                  src="https://empowering.cloud/wp-content/uploads/2023/04/Kurmi-Orange-square@3x-e1681904726292.png"
                  alt="Kurmi "
                  fit="contain"
                  duration={2000}
                />
              </a>
            </Grid>
            <Grid item xs={4} md={2}>
              <a href="https://loopup.com/" target="_blank" rel="noreferrer">
                <Image src="https://empowering.cloud/wp-content/uploads/2023/06/loopup-logo-supporter.jpg" alt="LoopUp" fit="contain" duration={2000} />
              </a>
            </Grid>
            <Grid item xs={4} md={2}>
              <a href="https://www.oakinnovate.com/" target="_blank" rel="noreferrer">
                <Image src="https://empowering.cloud/wp-content/uploads/2023/08/Oak-logo-2023-002.png" alt="Oak Innovations" fit="contain" duration={2000} />
              </a>
            </Grid>
            <Grid item xs={4} md={2}>
              <a href="https://www.enowsoftware.com/" target="_blank" rel="noreferrer">
                <Image src="https://empowering.cloud/wp-content/uploads/2023/02/ENow-Logo-002.png" alt="ENow" fit="contain" duration={2000} />
              </a>
            </Grid>
            <Grid item xs={4} md={2}>
              <a href="https://www.8x8.com/" target="_blank" rel="noreferrer">
                <Image src="https://empowering.cloud/wp-content/uploads/2024/03/8x8-logo-300x135-1.png" alt="8x8" fit="contain" duration={2000} />
              </a>
            </Grid>
          </Grid>

          <Grid container spacing={8} justifyContent="center" alignItems="center" direction="row" sx={{ pb: 4 }}>
            <Grid item xs={4} md={2}>
              <a href="https://www.cybertwice.com/" target="_blank" rel="noreferrer">
                <Image src="https://empowering.cloud/wp-content/uploads/2024/03/CyberTwice-300x50-1.png" alt="CyberTwice" fit="contain" duration={2000} />
              </a>
            </Grid>

            <Grid item xs={4} md={2}>
              <a href="https://tango-networks.com/" target="_blank" rel="noreferrer">
                <Image src="https://empowering.cloud/wp-content/uploads/2023/03/Tango-Logo.png" alt="Tango Networks" fit="contain" duration={2000} />
              </a>
            </Grid>
            <Grid item xs={4} md={2}>
              <a href="https://www.cyviz.com/" target="_blank" rel="noreferrer">
                <Image src="https://empowering.cloud/wp-content/uploads/2023/06/Cyviz_Black-002.png" alt="Cyviz" fit="contain" duration={2000} />
              </a>
            </Grid>
            <Grid item xs={4} md={2}>
              <a href="https://www.shure.com/en-US/conferencing-meetings/" target="_blank" rel="noreferrer">
                <Image src="https://empowering.cloud/wp-content/uploads/2024/01/Shure-300x100-1.png" alt="Shure" fit="contain" duration={2000} />
              </a>
            </Grid>
            <Grid item xs={4} md={2}>
              <a href="https://www.thepartneriq.com/" target="_blank" rel="noreferrer">
                <Image src="https://empowering.cloud/wp-content/uploads/2024/04/PartnerIQ-Logo-300x320-1.png" alt="Partner IQ" fit="contain" duration={2000} />
              </a>
            </Grid>
          </Grid>
        </Paper>
      </Box>

      <Box pb={5} textAlign="center">
        <Paper elevation={0} sx={{ p: 3 }}>
          <Typography variant="h4" sx={{ pb: 1 }}>
            Event Partnerships
          </Typography>
          {/* <Typography variant="body1" sx={{ pb: 4 }}>
            Supporters have also kindly chosen to contribute to support the work of Empowering.Cloud, we thank them for their support.
          </Typography> */}
          <Grid container spacing={8} justifyContent="center" alignItems="center" direction="row">
            <Grid item xs={4} md={2}>
              <a href="https://dtxevents.io/europe/en/page/dtx-europe" target="_blank" rel="noreferrer">
                <Image src="https://empowering.cloud/wp-content/uploads/2024/06/UCX-Dates-Black.png" alt="UC Expo" fit="contain" duration={2000} />
              </a>
            </Grid>
            <Grid item xs={4} md={2}>
              <a href="https://www.southcoastsummit.com/" target="_blank" rel="noreferrer">
                <Image src="https://empowering.cloud/wp-content/uploads/2022/09/SCS-300.png" alt="South Coast Summit" fit="contain" duration={2000} />
              </a>
            </Grid>
            <Grid item xs={4} md={2}>
              <a href="https://www.commsverse.com/" target="_blank" rel="noreferrer">
                <Image src="https://empowering.cloud/wp-content/uploads/2022/09/Commsverse.png" alt="Commsverse" fit="contain" duration={2000} />
              </a>
            </Grid>
            <Grid item xs={4} md={2}>
              <a href="https://www.commsvnext.com/" target="_blank" rel="noreferrer">
                <Image src="https://empowering.cloud/wp-content/uploads/2023/02/CVN2020Sm.png" alt="Comms vNext" fit="contain" duration={2000} />
              </a>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  );
};

export default BenefactorsPage;
