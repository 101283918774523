import { styled } from '@mui/material/styles';

export const ECFooter = styled('footer')(({ theme }) => ({
  marginTop: 'auto',
  backgroundColor: '#ffffff',
}));

export const ECFooterBox = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),

  [theme.breakpoints.up('md')]: {
    display: 'flex',
    alignItems: 'center',
  },
  [theme.breakpoints.down('md')]: {
    '> *': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    img: {
      marginRight: 0,
    },
    a: {
      margin: 0,
    },
    '.MuiTypography-caption': {
      paddingTop: theme.spacing(1.5),
    },
  },
}));
