import { Box, Divider, Drawer, Grid, IconButton, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import mobileNavLogo from '../../styles/eclogo-orange.png';
import CloseIcon from '@mui/icons-material/Close';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface MobileNavigationProps {
  handleDrawerToggle: () => void;
  mobileOpen: boolean;
}

interface RouteItem {
  name: string;
  url: string;
  isExternal: boolean;
}

interface RouteItems extends Array<RouteItem> {}

const MobileNavigation = (MobileNavigationProps: MobileNavigationProps) => {
  const { t: translate } = useTranslation(['navigation']);
  const { handleDrawerToggle, mobileOpen } = MobileNavigationProps;

  const routeItems: RouteItems = [
    { name: translate('navigation:home'), url: '/', isExternal: false },
    { name: translate('navigation:community'), url: 'https://events.empowering.cloud', isExternal: true },
    { name: translate('navigation:briefings'), url: '/briefings', isExternal: false },
    { name: translate('navigation:author'), url: '/author', isExternal: false },
    { name: translate('navigation:learningpaths'), url: '/learningpaths', isExternal: false },
    //  { name: translate('navigation:enablement'), url: '/enablement' },
    // { name: translate('navigation:research'), url: '/research', isExternal: false },
    { name: translate('navigation:research'), url: 'https://members.empowering.cloud', isExternal: true },
    //{ name: translate('navigation:community'), url: '/community' },

    { name: translate('navigation:benefactors'), url: '/sponsors', isExternal: false },
  ];

  const CustomInternalListItem = ({ to, item }: { to: string; item: string }) => (
    <ListItem component={RouterLink} to={to} key={item} disablePadding sx={{ width: 'unset' }}>
      <ListItemButton sx={{ textAlign: 'center' }}>
        <ListItemText secondary={item} />
      </ListItemButton>
    </ListItem>
  );

  let CustomExternalListItem = ({ to, item }: { to: string; item: string }) => (
    <ListItem key={item} disablePadding sx={{ width: 'unset' }}>
      <ListItemButton href={to} target="_blank" sx={{ textAlign: 'center' }}>
        <ListItemText secondary={item} />
      </ListItemButton>
    </ListItem>
  );

  const mobileDrawer = (
    <>
      <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', mx: 4, cursor: 'pointer' }}>
        <Grid container sx={{ alignItems: 'center' }}>
          <Grid item xs={1}>
            <IconButton onClick={handleDrawerToggle}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={10}>
            <Box
              component="img"
              sx={{
                height: 75,
                py: 1,
              }}
              alt="Empowering Cloud Logo"
              src={mobileNavLogo}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
            <List>
              {routeItems.map((item, index) => (
                <>
                  {item.isExternal === false && <CustomInternalListItem item={item.name} to={item.url} key={`mobile-nav-${index}`}></CustomInternalListItem>}
                  {item.isExternal === true && <CustomExternalListItem item={item.name} to={item.url} key={`mobile-nav-${index}`}></CustomExternalListItem>}
                </>
              ))}
            </List>
          </Grid>
        </Grid>
      </Box>
    </>
  );

  return (
    <Box component="nav">
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100%' },
        }}
      >
        {mobileDrawer}
      </Drawer>
    </Box>
  );
};

export default MobileNavigation;
