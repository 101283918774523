import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import Auth0Component from './components/Auth/Auth0Component';
import theme from './styles/theme';
import { HelmetProvider } from 'react-helmet-async';

// Initialize languages
import './locales/i18n';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      suspense: true,
    },
  },
});
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <Auth0Component>
        <QueryClientProvider client={queryClient}>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </QueryClientProvider>
      </Auth0Component>
    </BrowserRouter>
  </ThemeProvider>,
);

reportWebVitals();
