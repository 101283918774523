import { useAuth0 } from '@auth0/auth0-react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import ApiProvider from '../providers/ApiProvider';

export type AssetWatchedProgress = {
  percentWatched: number;
  lastWatched: string;
  videoEmbedId: string;
  displayName: string;
  assetId: string;
  assetTypeID: string;
  assetUrl: string;
  thumbnailUrl: string;
  userid: number;
  authorName: string;
};

export default function useWatchHistory(): UseQueryResult<AssetWatchedProgress[], unknown> {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery(['watch-history'], async () => {
    const itemsToReturn = 10;
    const accessToken = await getAccessTokenSilently();

    const { data } = await ApiProvider.get(`/asset/GetWatchHistory?count=${itemsToReturn}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  });
}
