import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Link, Container, Divider, Grid, LinearProgress, Tab, Typography, useTheme } from '@mui/material';
import { useParams, Link as RouterLink, useLocation } from 'react-router-dom';
import { InlineShareButtons } from 'sharethis-reactjs';
import useAsset from '../hooks/UseAsset';
import FullWidthComponent from '../components/FullWidthComponent';
import ReactPlayer from 'react-player/wistia';
import { useTranslation } from 'react-i18next';
import useAssetInLearningPath from '../hooks/UseAssetInLearningPath';
import { getAssetUrl } from '../helpers/AssetUrlHelper';
import UserAvatar from '../components/Auth/UserAvatar';
import { PageMeta } from '../components/PageMeta/PageMeta';
import VideoNotLoggedInComponent from '../components/Video/VideoNotLoggedInComponent';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useState } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


const VideoPage: React.FC = () => {
  let { videoId } = useParams();
  const { user, isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const { data } = useAsset(videoId);
  const { data: learningPathAssetData } = useAssetInLearningPath(videoId);
  const { t } = useTranslation(['common']);
  const theme = useTheme();
  const location = useLocation();
  const [videoTabPanelValue, setVideoTabPanelValue] = useState(user ? '1' : '2');

  const videoConfig = {
    options: {
      videoFoam: true,
      email: user && user['http://empowering.cloud/id'],
      seo: false,
    },
  };

  const handleVideoTabPanelChange = (event: React.SyntheticEvent, newValue: string) => {
    setVideoTabPanelValue(newValue);
  };

  return (
    <>
      {isLoading && <LinearProgress />}
      {!isLoading && data && (
        <>
          <PageMeta
            title={`${data.displayName}`}
            description={`${data.displayName} - ${data.category?.name}.`}
            openGraphTitle={`${data.displayName} on Empowering.Cloud`}
            openGraphDescription={`${data.displayName} - ${data.category?.name}.`}
            openGraphImageUrl={`${data.thumbnailUrl}`}
          />
          <Typography variant="h1" textAlign="center" fontWeight={300} sx={{ pb: 2 }} textTransform="uppercase">
            {data.displayName}
          </Typography>
          <FullWidthComponent sx={{ paddingY: 4 }}>
            <Container maxWidth="xl">
              <Grid container spacing={2}>
                <Grid item xs={12} lg={8}>
                  {user && isAuthenticated && !data.isComingSoon && (
                    <ReactPlayer
                      url={`https://home.wistia.com/medias/${data.embedUrl}`}
                      width="100%"
                      height="100%"
                      config={videoConfig}
                      controls={true}
                      playing={true}
                    />
                  )}
                  {user && isAuthenticated && data.isComingSoon && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center', width: '100%' }}>
                      <Typography>Coming soon...</Typography>
                      <Typography variant="body2">This video isn't ready to be watched yet. Please come back later.</Typography>
                    </Box>
                  )}
                  {!user && !isAuthenticated && <VideoNotLoggedInComponent videoThumbnailUrl={data.thumbnailUrl} />}
                </Grid>
                <Grid item xs={12} lg={4}>
                  {user && learningPathAssetData && (
                    <Box sx={{ mb: 2 }}>
                      <>
                        <Typography variant="h6">Learning Path</Typography>
                        <Divider sx={{ mb: 1 }} />
                        <Box sx={{ pb: 1 }}>
                          <Link component={RouterLink} color={theme.palette.secondary.dark} to={'/learningpaths'} title="Learning Paths">
                            <Typography variant="body1" sx={{ fontWeight: 900 }}>
                              {learningPathAssetData.learningPathName}
                            </Typography>
                          </Link>
                        </Box>
                        {learningPathAssetData.nextAsset && (
                          <>
                            {/* <Typography variant="subtitle1" fontWeight={700}>
                              Next on learning path:
                            </Typography> */}
                            {/* <Button
                              component={RouterLink}
                              to={getAssetUrl(
                                learningPathAssetData.nextAsset?.id.toString(),
                                learningPathAssetData.nextAsset?.embedUrl,
                                learningPathAssetData.nextAsset?.assetUrl,
                                learningPathAssetData.nextAsset?.assetType?.name,
                              )}
                              variant="outlined"
                              endIcon={<ArrowForwardIcon />}
                              color="secondary"
                              sx={{ width: '100%', textTransform: 'none', display: 'flex' }}
                              data-o-account-activity="VideoPage Next Video Button"
                            > */}
                            {/* <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center', width: '100%' }}>
                                <Typography>{learningPathAssetData.nextAsset.displayName}</Typography>
                                {learningPathAssetData.nextAsset.lengthMins !== 0 && (
                                  <Typography variant="body2">{learningPathAssetData.nextAsset.lengthMins} minutes</Typography>
                                )}
                              </Box> */}
                            <Button
                              component={RouterLink}
                              to={`/learningpaths`}
                              variant="outlined"
                              endIcon={<ArrowForwardIcon />}
                              color="secondary"
                              sx={{ width: '100%', textTransform: 'none', display: 'flex' }}
                              data-o-account-activity="VideoPage Next Video Button"
                            >
                              Next on Learning Path
                            </Button>
                          </>
                        )}
                      </>
                    </Box>
                  )}
                  {!user && !isAuthenticated && (
                    <Box sx={{ mb: 2 }}>
                      <Typography variant="h5" fontWeight={700}>
                        Join the free Empowering.Cloud Microsoft Teams Community to watch this briefing.
                      </Typography>
                      <Divider sx={{ mb: 1 }} />
                      <Typography variant="body2" paragraph>
                        Empowering.Cloud's mission is to provide IT Professionals with the best and most up to date Microsoft Teams and ecosystem insights,
                        advice and training free on-demand.
                      </Typography>
                      <Typography variant="body2" paragraph>
                        Join to watch this briefing and also get access to:
                        <ul>
                          <li>Monthly 15 minute Microsoft Teams News and Updates Briefings</li>
                          <li>Microsoft Teams and ecosystem advice from subject matter experts</li>
                          <li>Leaning Paths for Microsoft Teams exams</li>
                        </ul>
                      </Typography>
                      <Typography variant="body2" paragraph>
                        Thanks to our <RouterLink to="/sponsors">sponsors</RouterLink> Empowering.Cloud will always provide the best Microsoft Teams insights,
                        advice and training free for IT Professionals.
                      </Typography>

                      <Typography variant="body2">
                        You can simply{' '}
                        <RouterLink
                          to={location.pathname}
                          onClick={() => {
                            loginWithRedirect({ appState: { returnTo: location.pathname } });
                          }}
                        >
                          sign in with your LinkedIn account{' '}
                        </RouterLink>
                        or{' '}
                        <RouterLink
                          to={location.pathname}
                          onClick={() => {
                            loginWithRedirect({ appState: { returnTo: location.pathname } });
                          }}
                        >
                          sign up with your email free{' '}
                        </RouterLink>
                        No spam, we promise.
                      </Typography>
                    </Box>
                  )}
                  <Box sx={{ mb: 2 }}>
                    <Box sx={{ display: 'flex' }}>
                      <Link component={RouterLink} to={`/author/${data.author.urlSlug}`} sx={{ textDecoration: 'none' }}>
                        <UserAvatar authorName={data.author.name} authorImageSrc={data?.author?.pictureUrl} />
                      </Link>
                      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', pl: 1 }}>
                        <Typography variant="overline" sx={{ lineHeight: '1rem' }}>
                          {t('common:author')}
                        </Typography>
                        <Link
                          component={RouterLink}
                          to={`/author/${data.author.urlSlug}`}
                          variant="subtitle2"
                          color={theme.palette.secondary.dark}
                          fontWeight={700}
                        >
                          {data.author.name}
                        </Link>
                      </Box>
                    </Box>
                    <Typography variant="body2" pt={1}>
                      {data?.author?.shortDescription}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h6">Briefing Information</Typography>
                    <Divider sx={{ mb: 1 }} />
                    {data.subtitle && (
                      <Typography variant="body2" pb={1}>
                        {data.subtitle}
                      </Typography>
                    )}
                    <Typography variant="body2">Category: {data.category.name}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Container>
            {/* <Container maxWidth="xl" sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <InlineShareButtons
                  config={{
                    alignment: 'left',
                    color: 'social',
                    enabled: true,
                    font_size: 16,
                    labels: 'cta',
                    language: 'en',
                    networks: ['linkedin', 'twitter'],
                    padding: 12,
                    radius: 4,
                    show_total: false,
                    size: 40,
                    // OPTIONAL PARAMETERS
                    description: `I've just watched ${data.displayName} by ${data.author.name}

Check it out for free! #MicrosoftTeams #Office365

`, // (defaults to og:description or twitter:description)
                    title: `I've just watched ${data.displayName} by ${data.author.name}

Check it out for free! #MicrosoftTeams #Office365

`, // (defaults to og:title or twitter:title)
                    message: `I've just watched ${data.displayName} by ${data.author.name}

Check it out for free at https://app.empowering.cloud/briefings/${data.id}/${data.assetUrl}`, // (only for email sharing)
                    subject: `Empowering.Cloud: ${data.displayName}`, // (only for email sharing)
                  }}
                />
              </Grid>
            </Container> */}
            <Container maxWidth="lg" sx={{ mt: 2 }}>
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={videoTabPanelValue}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleVideoTabPanelChange} aria-label="Video Extra Information" centered variant="fullWidth">
                      {/* {user && isAuthenticated && data.circlePostSlug && <Tab label="Comments" value="1" sx={{ fontWeight: 'bold' }} />} */}
                      <Tab label="Summary" value="1" sx={{ fontWeight: 'bold' }} />
                      {data.assetTranscript && <Tab label="Transcript" value="2" sx={{ fontWeight: 'bold' }} />}
                    </TabList>
                  </Box>
                  {/* {user && isAuthenticated && (
                    <TabPanel value="1">
                      <Box>
                        {data.circlePostSlug.length > 0 && <VideoCircleCommentsIntegration slugUrl={data.circlePostSlug}></VideoCircleCommentsIntegration>}
                      </Box>
                    </TabPanel>
                  )} */}
                  <TabPanel value="1">
                    <Box>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data.assetFreeTextDescriptionHtml,
                        }}
                      />
                    </Box>
                  </TabPanel>
                  <TabPanel value="2">
                    <Box>
                      <h3>
                        Transcript Disclaimer: The following is the output of transcribing from a video recording and may be incomplete or inaccurate in places.
                        It is intended to be used as aid to understanding the video, not as a replacement for watching it. It should not be treated as an
                        authoritative record.
                      </h3>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data.assetTranscript,
                        }}
                      />
                    </Box>
                  </TabPanel>
                </TabContext>
              </Box>
            </Container>
          </FullWidthComponent>
        </>
      )}
    </>
  );
};

export default VideoPage;
