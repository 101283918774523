import { Helmet } from 'react-helmet-async';

export interface ISiteMetadataProps {
  title?: string;
  description?: string;
  openGraphTitle?: string;
  openGraphDescription?: string;
  openGraphImageUrl?: string;
}

export const PageMeta = (props: ISiteMetadataProps) => {
  const { title, description, openGraphTitle, openGraphDescription, openGraphImageUrl } = props;

  const openGraphImageUrlFallback = openGraphImageUrl
    ? openGraphImageUrl
    : 'https://empowering.cloud/wp-content/uploads/2022/09/Empowering.Cloud-black-text.png';

  return (
    <Helmet>
      {title && <title>{title} on Empowering.Cloud</title>}
      {description && <meta name="description" content={description} />}
      {openGraphTitle && <meta property="og:title" content={openGraphTitle} />}
      {openGraphDescription && <meta property="og:description" content={openGraphDescription} />}
      {getUrl() && <meta property="og:url" content={getUrl()} />}
      <meta property="og:image" content={openGraphImageUrlFallback} />
      <meta property="twitter:image" content={openGraphImageUrlFallback} />
    </Helmet>
  );

  function getUrl(): string {
    if (typeof window == 'undefined') return '';
    return window.location.href;
  }
};
