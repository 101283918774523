import { useQuery, UseQueryResult } from '@tanstack/react-query';
import ApiProvider from '../providers/ApiProvider';
import { useAuth0 } from '@auth0/auth0-react';
import { AxiosRequestHeaders } from 'axios';

export type ManagerReport = {
  powerBIReportID: string;
  powerBIDatasetID: string;
};

export default function useManagerReport(): UseQueryResult<ManagerReport[], unknown> {
  const { getAccessTokenSilently, user } = useAuth0();

  return useQuery(['manager-reports'], async () => {
    const headers: AxiosRequestHeaders = {};
    if (user) {
      const accessToken = user != null ? await getAccessTokenSilently() : null;
      headers.Authorization = `Bearer ${accessToken}`;
    }

    const url = `/reporting/getManagerReportToken`;
    const { data } = await ApiProvider.get(url, {
      headers: headers,
    });

    return data;
  });
}
