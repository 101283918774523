import { Button, Paper, Typography, LinearProgress } from '@mui/material';
import { useParams, Link as RouterLink, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import useTeamsSync from '../hooks/UseTeamsSyncMutation';

const TeamsUserSyncPage: React.FC = () => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  let { u } = useParams();
  //const [syncDone, setSyncDone] = useState(false);
  const { data } = useTeamsSync(u);
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      if (data) {
        loginWithRedirect({ appState: { returnTo: "/" } });
      }
    }, 5000);
  }, []);

  return (
    <>
      {isLoading && <LinearProgress />}
      {isAuthenticated ? (
        <Paper elevation={0} sx={{ padding: 5, textAlign: 'center' }}>
          {data ? (
            <>
              <Typography variant="h1" fontWeight={700}>
                Thanks!
              </Typography>
              <Typography variant="body1">Your accounts have been synced. You can now close this page, or explore other areas of Empowering.Cloud.</Typography>
              <Button component={RouterLink} to="/" variant="contained" disableElevation sx={{ mt: 3 }}>
                Empowering.Cloud Home
              </Button>
               <Typography variant="body1" sx={{ padding: 5, textAlign: 'center' }}>You'll be directed to the Empowering.Cloud homepage soon.</Typography>
            </>
          ) : (
            <>
              <Typography variant="h1" fontWeight={700}>
                Syncing...
              </Typography>
              <Typography variant="body1">Please wait whilst we sync your Microsoft Teams and Empowering.Cloud accounts.</Typography>
            </>
          )}
        </Paper>
      ) : (
        <>
          <Paper elevation={0} sx={{ padding: 5, textAlign: 'center' }}>
            <Typography variant="h1" fontWeight={700}>
              You are not logged in.
            </Typography>
            <Typography variant="body1">To sync your account with Microsoft Teams, you need to be signed in. Please sign-in and try again.</Typography>
            <Typography variant="body1">
              {' '}
              <Button
                sx={{
                  textTransform: 'none',
                  verticalAlign: 'inherit',
                  px: '4px',
                  ':hover': { textDecoration: 'underline', backgroundColor: 'unset' },
                }}
                onClick={() => {
                  loginWithRedirect({ appState: { returnTo: location.pathname } });
                }}
                disableRipple
              >
                sign up or login
              </Button>
            </Typography>
          </Paper>
        </>
      )}
    </>
  );
};

export default TeamsUserSyncPage;
