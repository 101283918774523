import { useState, useEffect } from 'react';

export interface Link {
  title: string | undefined;
  id: string;
}

export function useSkipLinks() {
  const [skipLinks, setSkipLinks] = useState<Link[]>([]);

  useEffect(() => {
    const skipLinkElements = document.querySelectorAll('[data-skip-link]:not(pre):not(code)');

    const newdata: Link[] = Array.from(skipLinkElements).map(skipLink => {
      if (skipLink instanceof HTMLElement) {
        return {
          id: skipLink.id,
          title: skipLink.dataset.skipLink,
        };
      } else {
        return {
          id: skipLink.id,
          title: '',
        };
      }
    });

    setSkipLinks(newdata);
  }, []);

  return { skipLinks };
}
