import { Button, Link, Paper, Typography } from '@mui/material';
import axios from 'axios';
import { useEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useAuth0 } from '@auth0/auth0-react';

const ErrorComponent: React.FC<FallbackProps> = ({ resetErrorBoundary, error }) => {
  const isAxiosError = axios.isAxiosError(error);
  const is404Error = isAxiosError && error.response?.status === 404;
  const nowTimestamp = new Date();
  const { isLoading, isAuthenticated, loginWithRedirect, logout, user } = useAuth0();

  useEffect(() => {
    if (is404Error) {
      window.location.href = '/404';
    }
  }, [is404Error]);

  return (
    <>
      {!is404Error && (
        <Paper elevation={0} sx={{ padding: 5, textAlign: 'center' }}>
          <Typography variant="h1" fontWeight={700}>
            Oops!
          </Typography>
          <Typography variant="body1">
            Looks like something has gone wrong, we're really sorry about that. You can try again by pressing below or try refreshing the page.
          </Typography>

          <Button
            onClick={() => {
              logout({ logoutParams: { returnTo: '/login' } });
            }}
            variant="contained"
            disableElevation
            sx={{ mt: 3 }}
            data-o-account-activity="Error Button Start New Session"
          >
            Start New Session
          </Button>

          <Typography variant="overline" display="block" gutterBottom>
            {nowTimestamp.toUTCString()} | {error?.name} | {error?.message} | {window.location.href}
          </Typography>
        </Paper>
      )}
    </>
  );
};

export default ErrorComponent;
