export const getAssetUrl = (id: string, embedUrl: string, friendlyUrl: string, assetType: string) => {
  switch (assetType) {
    case 'Video':
      return `/briefings/${id}/${friendlyUrl}`;
    case 'Quiz':
      return `/quizzes/${embedUrl}/${friendlyUrl}`;
    default:
      return `/briefings/${id}/${friendlyUrl}`;
  }
};
