import { alpha, List, styled } from '@mui/material';

interface Props {
  component: React.ElementType;
  direction: string;
}

export const ECNavigationBarWithStyles = styled(List)<Props>(({ theme }) => ({
  '& .MuiListItemButton-root': {
    backgroundColor: '#ffffff',
  },
  '& .MuiListItemButton-root.Mui-selected': {
    backgroundColor: '#ffffff',
    p: {
      fontWeight: 'bold',
      textDecoration: 'underline',
      textUnderlineOffset: '0.2em',
      textDecorationColor: theme.palette.primary.main,
    },
  },
  '.MuiListItemButton-root:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
}));
