import { Box, IconButton } from '@mui/material';
import { Image } from 'mui-image';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { useLocation } from 'react-router-dom';

interface VideoNotLoggedInComponentProps {
  videoThumbnailUrl: string;
}

const VideoNotLoggedInComponent: React.FC<VideoNotLoggedInComponentProps> = ({ videoThumbnailUrl }) => {
  const { loginWithRedirect } = useAuth0();
  const location = useLocation();

  return (
    <Box sx={{ position: 'relative' }}>
      <Image src={videoThumbnailUrl} fit="contain" duration={1000} width="100%" />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          height: '100%',
          width: '100%',
          opacity: 0.4,
          cursor: 'pointer',
          '&:hover': { opacity: 0.8 },
        }}
      >
        <IconButton
          color="primary"
          aria-label="Sign Up / Sign In"
          component="label"
          sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}
          onClick={() => {
            loginWithRedirect({ appState: { returnTo: location.pathname } });
          }}
        >
          <PlayCircleFilledWhiteIcon sx={{ fontSize: 180 }} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default VideoNotLoggedInComponent;
