import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Box,
  Typography,
  Button,
  styled,
  TableCell,
  tableCellClasses,
  CircularProgress,
  CircularProgressProps,
} from '@mui/material';
import { LearningPathStep, PrimaryLearningPath } from '../../hooks/UsePrimaryLearningPathDetail';
import { Link as RouterLink } from 'react-router-dom';
import VideocamIcon from '@mui/icons-material/Videocam';
import QuizIcon from '@mui/icons-material/Quiz';
import Background from '../SocialIcons/background';

interface LearningPathsTableProps {
  learningPath: PrimaryLearningPath;
}

const LearningPathsTable: React.FC<LearningPathsTableProps> = ({ learningPath }) => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontWeight: '700',
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: 16,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const getAssetUrlFromStep = (step: LearningPathStep) => {
    switch (step.assetTypeId) {
      case 1:
        return `/briefings/${step.assetID}/${step.assetURL}`;
      case 2:
        return `/quizzes/${step.embedUrl}/${step.assetURL}`;
      default:
        return `/briefings/${step.embedUrl}/${step.assetURL}`;
    }
  };

  const getAssetIcon = (step: LearningPathStep) => {
    switch (step.assetTypeId) {
      case 1:
        return <VideocamIcon color="disabled" />;
      case 2:
        return <QuizIcon color="disabled" />;
      default:
        return <VideocamIcon color="disabled" />;
    }
  };

  const getDoAgainWording = (step: LearningPathStep) => {
    switch (step.assetTypeId) {
      case 1:
        return 'Watched';
      case 2:
        return 'Passed';
      default:
        return 'Complete again';
    }
  };

  const getSpecialButtonStyle = (progress: number) => {
    if (progress >= 75) return { backgroundColor: '#474848' };
  };

  function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex', verticalAlign: 'middle' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <TableContainer component={Paper} sx={{ mt: 3 }}>
      <Table sx={{ minWidth: { md: 650 } }} size="small" aria-label="Learning Path Detail">
        <TableHead>
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            {/* <StyledTableCell>Watched Percentage</StyledTableCell> */}
            <StyledTableCell></StyledTableCell>
            <StyledTableCell>Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {learningPath.steps.map((step, index) => {
            return (
              <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row">
                  <Box sx={{ display: 'flex', alignItems: 'left', flexWrap: 'wrap' }}>
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>{getAssetIcon(step)}</Box>
                    <Typography variant="body2" sx={{ pl: 1 }}>
                      {step.assetName}
                    </Typography>
                  </Box>
                </StyledTableCell>
                <StyledTableCell>{/* <CircularProgressWithLabel value={step.progress} /> */}</StyledTableCell>
                <StyledTableCell>
                  {/* TODO: enable this once API returns isComingSoon for learning path steps {!step.isComingSoon && ( */}
                  {!learningPath.learningPathIsLinear || step.showInALinearLearningPath ? (
                    <Button
                      component={RouterLink}
                      to={getAssetUrlFromStep(step)}
                      variant="contained"
                      disableElevation
                      size="small"
                      style={getSpecialButtonStyle(step.progress)}
                    >
                      {step.progress >= 75 ? getDoAgainWording(step) : step.progress > 0 ? 'Continue' : 'Start'}
                    </Button>
                  ) : (
                    <></>
                  )}
                  {/* )} */}
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LearningPathsTable;
