import { alpha, createTheme, responsiveFontSizes } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import React from 'react';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    fontWeightThin: number;
    breadcrumbs: React.CSSProperties;
    footerLinks: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    fontWeightThin: number;
    breadcrumbs?: React.CSSProperties;
    footerLinks?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    fontWightThin: true;
    breadcrumbs: true;
    footerLinks: true;
  }
}

let theme = createTheme({
  palette: {
    background: {
      default: '#f6f6f6',
    },
    primary: {
      main: '#ff634b',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#19857b',
    },
    text: {
      secondary: alpha('#000', 0.8),
    },
    error: {
      main: red.A400,
    },
  },
  typography: {
    fontFamily: ['Lato', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    fontSize: 14,
    fontWeightThin: 100,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightBold: 700,
    breadcrumbs: {
      fontSize: 13,
    },
    footerLinks: {
      fontSize: 13,
    },
    h1: {
      fontSize: 24,
    },
    h2: {
      fontSize: 24,
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
