import { ListItem, ListItemButton, ListItemText, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import useFirstRun from '../../hooks/UseFirstRun';
import { ECNavigationBarWithStyles } from './ECNavigationBar.styles';

export interface RouteItem {
  name: string;
  url: string;
  isExternal: boolean;
}

export interface RouteItems extends Array<RouteItem> {}

const ECNavigationBar = () => {
  const { t } = useTranslation(['navigation']);
  const location = useLocation();
  const shouldDisplayFirstRun = useFirstRun();

  const routeItems: RouteItems = [
    { name: t('navigation:home'), url: '/', isExternal: false },
    { name: t('navigation:community'), url: 'https://events.empowering.cloud', isExternal: true },
    { name: t('navigation:briefings'), url: '/briefings', isExternal: false },
    { name: t('navigation:author'), url: '/author', isExternal: false },
    { name: t('navigation:learningpaths'), url: '/learningpaths', isExternal: false },
    //{ name: t('navigation:enablement'), url: '/enablement' },
    // { name: t('navigation:research'), url: '/research', isExternal: false },
    { name: t('navigation:research'), url: 'https://members.empowering.cloud', isExternal: true },
    //{ name: t('navigation:community'), url: '/community' },

    { name: t('navigation:benefactors'), url: '/sponsors', isExternal: false },
  ];

  let CustomInternalListItem = ({ to, item }: { to: string; item: string }) => (
    <ListItem
      data-o-account-activity={'Navigation Link ' + item}
      component={RouterLink}
      to={shouldDisplayFirstRun ? '#' : to}
      key={item}
      disablePadding
      sx={{ width: 'unset' }}
      disabled={shouldDisplayFirstRun}
    >
      <ListItemButton selected={to === location.pathname}>
        <ListItemText secondary={item} />
      </ListItemButton>
    </ListItem>
  );

  let CustomExternalListItem = ({ to, item }: { to: string; item: string }) => (
    <ListItem data-o-account-activity={'Navigation Link ' + item} key={item} disablePadding sx={{ width: 'unset' }}>
      <ListItemButton href={to} target="_blank">
        <ListItemText secondary={item} />
      </ListItemButton>
    </ListItem>
  );

  return (
    <ECNavigationBarWithStyles component={Stack} direction="row" id="navigation" data-skip-link="Go to Navigation">
      {routeItems.map((item, index) => (
        <>
          {item.isExternal === false && <CustomInternalListItem item={item.name} to={item.url} key={`nav-${index}`}></CustomInternalListItem>}
          {item.isExternal === true && <CustomExternalListItem item={item.name} to={item.url} key={`nav-${index}`}></CustomExternalListItem>}
        </>
      ))}
    </ECNavigationBarWithStyles>
  );
};

export default ECNavigationBar;
