import { useEffect, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import ApiProvider from '../../../providers/ApiProvider';

const OutsetaSetup = () => {
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();

  function getOutseta() {
    if (window['Outseta']) {
      return window['Outseta'];
    } else {
      throw new Error('Outseta is missing, have you added the script to head?');
    }
  }

  const outsetaRef = useRef(getOutseta());

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      (async () => {
        try {
          const token = await getAccessTokenSilently();
          const response = await ApiProvider.get('/me/GetCRMToken', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          var responseJson = await response.data;

          outsetaRef.current.setAccessToken(responseJson.access_token);
        } catch (e) {
          console.error(e);
        }
      })();
    }
  }, [getAccessTokenSilently, isLoading, isAuthenticated]);

  return <></>;
};

export default OutsetaSetup;
