import { Box, Paper, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { PageMeta } from '../components/PageMeta/PageMeta';

const SupportPage: React.FC = () => {
  return (
    <>
      <PageMeta
        title={`Support on Empowering.Cloud`}
        description={`For technical issues and questions, or to tell about something that's wrong or missing, please let us know`}
        openGraphTitle={`Support on Empowering.Cloud`}
        openGraphDescription={`For technical issues and questions, or to tell about something that's wrong or missing, please let us know.`}
      />
      <Box pb={5} textAlign="center">
        <Typography variant="h1" textAlign="center" fontWeight={300} sx={{ pb: 2 }} textTransform="uppercase">
          Questions &amp; Feedback
        </Typography>
        <Paper elevation={0} sx={{ p: 3 }}>
          {/* <Typography variant="h5" fontWeight={700} sx={{ pb: 2 }}>
            If you can't find what you're looking for, our&nbsp;
            <a href="https://ec.empowering.cloud" target='_blank'>
              amazing community
            </a>
            &nbsp; might be able to help you find it.
          </Typography> */}
          <Typography variant="body1">For technical issues and questions, or to tell about something that's wrong or missing, please let us know:</Typography>
        </Paper>
      </Box>
      <Paper elevation={0}>
        <Box id="support-form"></Box>
      </Paper>
      <Paper elevation={0} sx={{ mt: 5 }}>
        <Box sx={{ p: 2 }}>
          <Typography variant="body1" textAlign="center">
            Did you skip the welcome survey and now want to take it? Or maybe you want to revisit it? You can find it here:
          </Typography>
          <Typography variant="body1" textAlign="center">
            <Link to="/firstrun" title="first run survey" style={{ textAlign: 'center' }}>
              First Run Survey
            </Link>
          </Typography>
        </Box>
      </Paper>
    </>
  );
};

export default SupportPage;
