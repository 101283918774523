import { TableContainer, Paper, Table, TableHead, TableRow, TableBody, Box, Typography, Button, styled, TableCell, tableCellClasses } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import VideocamIcon from '@mui/icons-material/Videocam';
import QuizIcon from '@mui/icons-material/Quiz';
import { Asset } from '../../hooks/UseAsset';
import { getAssetUrl } from '../../helpers/AssetUrlHelper';

interface AssetTableProps {
  assets: Asset[];
}

const AssetTable: React.FC<AssetTableProps> = ({ assets }) => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontWeight: '700',
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: 16,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const getAssetIcon = (type: string) => {
    switch (type) {
      case 'Video':
        return <VideocamIcon />;
      case 'Quiz':
        return <QuizIcon />;
      default:
        return <VideocamIcon />;
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: { md: 650 } }} size="small" aria-label="Asset List">
        <TableHead>
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell>Options</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {assets.map((asset, index) => {
            return (
              <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row">
                  <Box sx={{ display: 'flex', alignItems: 'left', flexWrap: 'wrap' }}>
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>{getAssetIcon(asset.assetType.name)}</Box>
                    <Typography variant="body2" sx={{ pl: 1 }}>
                      {asset.displayName}
                    </Typography>
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  <Button
                    component={RouterLink}
                    to={getAssetUrl(asset.id?.toString(), asset.embedUrl, asset.assetUrl, asset.assetType?.name)}
                    variant="contained"
                    disableElevation
                    size="small"
                  >
                    View
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AssetTable;
