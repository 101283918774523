import { Box, Button, Chip, Paper, Stack, styled, TextField, Typography } from '@mui/material';
import useSearch, { SearchAsset } from '../hooks/UseSearch';
import AssetCard from '../components/Cards/AssetCard/AssetCard';
import GroupsIcon from '@mui/icons-material/Groups';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { PageMeta } from '../components/PageMeta/PageMeta';

const BriefingsPage: React.FC = () => {
  const { t: translate } = useTranslation(['common']);
  const [searchParams, setSearchParams] = useSearchParams();
  const searchTermQuery = searchParams.get('q') ?? '';
  const [localSearchTerm, setLocalSearchTerm] = useState('');
  const { data } = useSearch(searchTermQuery);

  useEffect(() => {
    setLocalSearchTerm(searchTermQuery);
  }, [searchParams]);

  const Item = styled('div')(({ theme }) => ({
    padding: theme.spacing(1),
  }));

  const handleChange = (searchTerm: string) => {
    setLocalSearchTerm(searchTerm);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSearchParams({ q: localSearchTerm });
    const elem = e.currentTarget.elements.namedItem('searchterm-inline') as HTMLInputElement;
    elem.blur();
  };

  const filterListItems: Array<string> = [
    'Calling and Meetings',
    'Microsoft Teams Phone',
    'Microsoft Teams Licensing',
    'Monthly Updates',
    'Microsoft Teams Fundamentals',
    'Network Settings for Microsoft Teams',
    'Security and compliance',
    'Other',
  ];

  return (
    <>
      <PageMeta
        title={`Briefings on Empowering.Cloud`}
        description={`Briefings on Empowering.Cloud`}
        openGraphTitle={`Briefings on Empowering.Cloud`}
        openGraphDescription={`Briefings on Empowering.Cloud`}
      />
      <Paper elevation={1} sx={{ mb: 3, p: 2 }}>
        <Stack>
          <Item>
            <Typography variant="overline" component="span">
              {translate('common:searchfilter')}
            </Typography>
          </Item>
          <Box padding={1} display={'flex'}>
            <form onSubmit={handleSubmit} style={{ display: 'flex' }}>
              <TextField
                name="searchterm-inline"
                key="searchterm-inline"
                label={translate('common:searchbox')}
                variant="outlined"
                size="small"
                sx={{ width: { md: 400 } }}
                value={localSearchTerm}
                onChange={event => handleChange(event.target.value)}
              />
              <Button type="submit" variant="contained" disableElevation sx={{ ml: 2, pb: 1 }}>
                {translate('common:search')}
              </Button>
            </form>
          </Box>
          <Item>
            {filterListItems.map((filterItem, index) => (
              <Chip
                key={`chip-${index}`}
                sx={{ mt: 1, mr: 1, p: 1 }}
                icon={<GroupsIcon />}
                label={filterItem}
                onClick={() => {
                  setSearchParams({ q: filterItem });
                  setLocalSearchTerm(filterItem);
                }}
              />
            ))}
          </Item>
        </Stack>
      </Paper>
      <Box>
        {data &&
          data.value.map((searchItem: SearchAsset) => {
            return <AssetCard Link={`/briefings/${searchItem.ID}/${searchItem.AssetUrl}`} {...searchItem} key={`searchcard-${searchItem.ID}`} />;
          })}
      </Box>
    </>
  );
};

export default BriefingsPage;
