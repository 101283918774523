import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  React.useEffect(() => {
    function checkUser() {
      if (isAuthenticated) {
        navigate('/');
      } else {
        loginWithRedirect();
      }
    }

    checkUser();
  }, [isAuthenticated, loginWithRedirect, navigate]);

  return (
    <Paper elevation={0} sx={{ padding: 5, textAlign: 'center' }}>
      <Typography variant="h1" fontWeight={700}>
        Logging You In
      </Typography>
      <Typography variant="body1">Please wait, we are logging you in.</Typography>
      <Button onClick={() => loginWithRedirect()} variant="contained" disableElevation sx={{ mt: 3 }}>
        Click Here if you aren't logged in automatically
      </Button>
    </Paper>
  );
};

export default LoginPage;
