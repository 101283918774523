import { useQuery, UseQueryResult } from '@tanstack/react-query';
import ApiProvider from '../providers/ApiProvider';
import { Page } from './UseBenefactors';

const getQAndA = async (): Promise<Page> => {
  const { data } = await ApiProvider.get('/page/GetQnaPage');
  return data;
};
export default function useQAndA(): UseQueryResult<Page, unknown> {
  return useQuery(['page-qanda'], getQAndA);
}
