import { useAuth0 } from '@auth0/auth0-react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import ApiProvider from '../providers/ApiProvider';

export type PrimaryLearningPath = {
  learningPathName: string;
  learningPathDescription: string;
  started: string;
  learningPathIsLinear: boolean;
  steps: LearningPathStep[];
};

export type LearningPathStep = {
  assetID: string;
  assetName: string;
  assetLength: number;
  assetURL: string;
  progress: number;
  embedUrl: string;
  assetTypeId: number;
  showInALinearLearningPath: boolean;
};

export default function usePrimaryLearningPathDetail(): UseQueryResult<PrimaryLearningPath, unknown> {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery(['primaryLearningPathDetail'], async () => {
    const accessToken = await getAccessTokenSilently();

    const { data } = await ApiProvider.get('/learningpath/GetMyPrimaryLearningPathDetail', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  });
}
