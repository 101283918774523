import { useAuth0 } from '@auth0/auth0-react';
import { Box, LinearProgress, Paper } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import useLocalStorage from '../hooks/UseLocalStorage';

interface SurveyEvent {
  isTrusted: boolean;
  data: string;
}

interface SurveyEventData {
  eventType: string;
  eventArguments: [];
}

const FirstRunPage: React.FC = () => {
  const { user, isLoading, isAuthenticated } = useAuth0();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isFirstRunComplete, setFirstRunComplete] = useLocalStorage('firstRunComplete', true);
  const navigate = useNavigate();

  const handleMessage = useCallback(
    (event: SurveyEvent) => {
      let data: SurveyEventData | undefined;
      try {
        data = JSON.parse(event.data);
      } catch (e) {}

      if (event.isTrusted && data?.eventType === 'surveyCompleted') {
        setFirstRunComplete(true);
        navigate('/');
      }
    },
    [navigate, setFirstRunComplete],
  );

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  return (
    <>
      {isLoading && <LinearProgress />}
      {user && isAuthenticated && (
        <>
          <Helmet>
            <script async src={`https://s.surveyanyplace.com/js/embed.js`} type="text/javascript" />
          </Helmet>
          <Paper sx={{ position: 'absolute', width: '100%', left: 0, padding: 2, display: 'flex', justifyContent: 'space-around' }} elevation={0}>
            <Box
              component="div"
              className="embed-survey"
              data-url={`https://s.pointerpro.com/ec-default-first-run?cf1='${user['http://empowering.cloud/email']}'&cf2='${user['http://empowering.cloud/id']}'`}
              sx={{ width: '80%', maxHeight: '800px' }}
            />
          </Paper>
        </>
      )}
    </>
  );
};

export default FirstRunPage;
