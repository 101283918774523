import { Box, Card, CardActionArea, CardMedia, styled } from '@mui/material';

export const AssetCardWithStyles = styled(Card)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(2),
  '&:hover': {
    borderColor: theme.palette.info.main,
  },
}));

interface AssetCardActionAreaWithStylesProps {
  component: React.ElementType;
  to: string;
}

export const AssetCardActionAreaWithStyles = styled(CardActionArea)<AssetCardActionAreaWithStylesProps>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'start',
  padding: theme.spacing(1),

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

interface AssetCardMediaProps {
  component?: React.ElementType;
  size: 'sm' | undefined;
  variant: 'square' | 'rounded' | undefined;
}

export const AssetCardMediaWithStyles = styled(CardMedia)<AssetCardMediaProps>(({ theme, size, variant }) => ({
  [theme.breakpoints.up('md')]: {
    width: size === 'sm' ? '150px' : '350px',
  },
  [theme.breakpoints.down('md')]: {
    width: size === 'sm' ? '150px' : 'inherit',
  },
  borderRadius: variant === 'rounded' ? 12 : 1,
}));

export const AssetCardFooterWithStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: 'auto',

  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(3),
  },
}));
