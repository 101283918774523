import { useAuth0 } from '@auth0/auth0-react';
import { Helmet } from 'react-helmet-async';

const IntercommSetup = () => {
  const { isLoading, user } = useAuth0();

  return (
    <>
      <Helmet>
        {!isLoading && user ? (
          <script>
            {`
             window.intercomSettings = {
                api_base: "https://api-iam.intercom.io",
                app_id: "dqyizbky",
                name: "${user['http://empowering.cloud/displayname']}", // Full name
                email: "${user['http://empowering.cloud/email']}", // Email address    
                user_id: "${user['http://empowering.cloud/id']}",
                user_hash: "${user['http://empowering.cloud/intercom_hash']}",
                outsetaid: "${user['http://empowering.cloud/oid']}",
                role: "${user['http://empowering.cloud/role']}",
              };
            `}
          </script>
        ) : (
          <script>
            {`
              window.intercomSettings = {
                api_base: "https://api-iam.intercom.io",
                app_id: "dqyizbky"
              };
           `}
          </script>
        )}
        <script>
          {`        
(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/dqyizbky';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
        `}
        </script>
      </Helmet>
    </>
  );
};

export default IntercommSetup;
