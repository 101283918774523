import { useAuth0 } from '@auth0/auth0-react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import ApiProvider from '../providers/ApiProvider';

export type Certificate = {
  id: number;
  name?: string;
  issued: string;
  link?: string;
};

export default function useCertificates(): UseQueryResult<Certificate[], unknown> {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery(['certificates'], async () => {
    const accessToken = await getAccessTokenSilently();

    const { data } = await ApiProvider.get('/me/GetMyCertificates', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  });
}
