import { Box, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { ECCardActionAreaWithStyles, ECCardContentWithStyles, ECCardHorizontalWithStyles, ECCardMediaWithStyles } from './ECCardHorizontal.styles';

export interface ECCardHorizontalProps {
  title: string;
  subtitle: string;
  imageUrl: string;
  imageAlt?: string;
  link?: string;
}

export const ECCardHorizontal = (props: ECCardHorizontalProps) => {
  const { title, subtitle, imageUrl, imageAlt, link } = props;

  return (
    <React.Fragment>
      <ECCardHorizontalWithStyles>
        <ECCardActionAreaWithStyles sx={{ display: 'flex', justifyContent: 'start' }} component={Link} to={link ? link : '#'}>
          <ECCardMediaWithStyles component="img" image={imageUrl} alt={imageAlt} />
          <Box sx={{ display: 'flex' }}>
            <ECCardContentWithStyles>
              <Typography component="div" variant="h6">
                {title}
              </Typography>
              <Typography variant="body2" color="text.secondary" component="div">
                {subtitle}
              </Typography>
            </ECCardContentWithStyles>
          </Box>
        </ECCardActionAreaWithStyles>
      </ECCardHorizontalWithStyles>
    </React.Fragment>
  );
};
