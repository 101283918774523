import { useAuth0 } from '@auth0/auth0-react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import ApiProvider from '../providers/ApiProvider';
import { PrimaryLearningPath } from './UsePrimaryLearningPathDetail';

export default function useLearningPathIndividualDetail(learningPathId: number): UseQueryResult<PrimaryLearningPath, unknown> {
  const { user, getAccessTokenSilently } = useAuth0();

  return useQuery(
    ['learningPathIndividualDetail', learningPathId],
    async () => {
      const accessToken = await getAccessTokenSilently();

      const { data } = await ApiProvider.get(`/learningpath/GetLearningPathDetail?learningPathId=${learningPathId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return data;
    },
    { enabled: !!user },
  );
}
