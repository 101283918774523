import { Box, Paper, Typography } from '@mui/material';
import { PageMeta } from '../components/PageMeta/PageMeta';
import useQAndA from '../hooks/UseQAndA';

const QAndAPage: React.FC = () => {
  const { data } = useQAndA();

  return (
    <>
      <PageMeta
        title={`${data?.pageTitle} on Empowering.Cloud`}
        description={`${data?.pageTitle} on Empowering.Cloud`}
        openGraphTitle={`${data?.pageTitle} on Empowering.Cloud`}
        openGraphDescription={`${data?.pageTitle} on Empowering.Cloud`}
      />
      {data && (
        <>
          <Typography variant="h1" textAlign="center" fontWeight={300} sx={{ pb: 2 }} textTransform="uppercase">
            {data.pageTitle}
          </Typography>
          <Box pb={5} textAlign="center">
            <Paper elevation={0} sx={{ p: 3 }}>
              <Box dangerouslySetInnerHTML={{ __html: data.pageContentHtml }} />
            </Paper>
          </Box>
        </>
      )}
    </>
  );
};

export default QAndAPage;
