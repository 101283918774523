import { useQuery, UseQueryResult } from '@tanstack/react-query';
import ApiProvider from '../providers/ApiProvider';

export type SearchResult = {
  value: Array<SearchAsset>;
};

export type SearchAsset = {
  ID: number;
  DisplayName: string;
  Author: string;
  AuthorPictureUrl: string;
  Subtitle: string;
  Type: string;
  Category: string;
  EmbedUrl: string;
  ThumbnailUrl: string;
  LastModified: string;
  LengthMins: number;
  AssetUrl: string;
};

const getSearch = async (searchTerm?: string): Promise<SearchResult> => {
  const url = searchTerm ? `/search/search?search=${searchTerm}` : '/search/search';
  const { data } = await ApiProvider.get(url);
  return data;
};

export default function useSearch(searchTerm?: string): UseQueryResult<SearchResult, unknown> {
  return useQuery(['search', searchTerm], () => getSearch(searchTerm));
}
