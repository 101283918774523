import { Card, CardActionArea, CardContent, CardMedia, styled } from '@mui/material';

export const ECCardHorizontalWithStyles = styled(Card)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(1),
  '&:last-child': { marginBottom: 0 },
}));

interface ECCardHorizontalWithStylesProps {
  component: React.ElementType;
  to: string;
}

export const ECCardActionAreaWithStyles = styled(CardActionArea)<ECCardHorizontalWithStylesProps>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'start',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

interface ECCardMediaWithStylesProps {
  component: React.ElementType;
  alt: string | undefined;
}

export const ECCardMediaWithStyles = styled(CardMedia)<ECCardMediaWithStylesProps>(({ theme }) => ({
  borderRadius: 1,
  [theme.breakpoints.up('md')]: {
    width: '350px',
    maxWidth: '220px',
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: 'initial',
  },
}));

export const ECCardContentWithStyles = styled(CardContent)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
  },
}));
