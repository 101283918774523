import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AssetCard from '../components/Cards/AssetCard/AssetCard';
import { PageMeta } from '../components/PageMeta/PageMeta';
import { Asset } from '../hooks/UseAsset';
import useQuiz from '../hooks/UseQuiz';

const QuizListPage: React.FC = () => {
  const { data } = useQuiz();
  const { t: translate } = useTranslation(['navigation']);

  return (
    <>
      <PageMeta
        title={`${translate('navigation:quizzes')} on Empowering.Cloud`}
        description={`${translate('navigation:quizzes')} on Empowering.Cloud`}
        openGraphTitle={`${translate('navigation:quizzes')} on Empowering.Cloud`}
        openGraphDescription={`${translate('navigation:quizzes')} on Empowering.Cloud`}
      />
      <Typography variant="h1" textAlign="center" fontWeight={300} sx={{ pb: 2 }} textTransform="uppercase">
        {translate('navigation:quizzes')}
      </Typography>
      {data && (
        <Grid container>
          {data.map((quiz: Asset, index: Number) => (
            <Grid item xs={12} key={`quiz-${index}`}>
              <AssetCard
                ID={quiz?.id}
                DisplayName={quiz?.displayName}
                Type={quiz?.assetType?.name}
                Category={quiz?.category?.name}
                Author={quiz?.author?.name}
                AuthorPictureUrl={quiz?.author.pictureUrl}
                AuthorUrl={quiz?.author.urlSlug}
                ThumbnailUrl={quiz?.thumbnailUrl}
                LastModified={quiz?.lastModified}
                Subtitle={quiz?.subtitle}
                Link={`/quizzes/${quiz?.embedUrl}/${quiz?.assetUrl}`}
                {...quiz}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default QuizListPage;
